@mixin label {
  font-size: 1em;
  font-variant: small-caps;
  color: #888;
}

.container {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
    
  //background-color: rgb(65, 64, 64);
}

.container_top,
.container_bottom {
  flex-flow: column;
}

.knob {
  order: 2;
  width: 70px !important;
  height: 2px !important;
  font-size: 0.8em;
}
.top {
  @include label;
  order: 1;
}

.bottom {
  @include label;
  order: 3;
}

.left {
  @include label;
  order: 1;
  margin-right: 10px;
}

.right {
  @include label;

  order: 3;
  margin-left: 10px;
}
