.label {
  display: inline-block;
  padding: 4px;
  border: 1px solid #666;
  border-radius: 4px;

  font-size: 0.8em;
  font-variant: small-caps;
  color: #888;

  margin-right: 4px;
  margin-bottom: 4px;
}

.label:hover {
  color: #eee;
}
