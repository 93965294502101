@mixin bordered {
  background-color: #222;
  border: 1px solid #444;
  border-radius: 4px;
}

.dateRangeLabel {
  text-align: center;
  font-size: 1em;
}

.pick-date {
  font-weight: 600;
  width: 97% !important;
}

.date-range-value-container {
  font-size: 0.9em;
  padding-top: 4px;
  text-align: center;
  //height: 31px;
  background-color: #222;
  @include bordered;
}

.range-arrow {
  margin-left: 8px;
  margin-right: 8px;
  color: #888;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #000;
  text-align: center;
  background-color: #888;
  border-radius: 0.25rem;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #888;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #222;
  background: linear-gradient(to left, #111 8%, #222 18%, #222 33%);
  background-size: 1000px 104px;
  height: 31px;
  position: relative;
}

.loading-value {
  //background: linear-gradient(270deg, #010101, #111);

  @extend .animated-background;
  align-self: flex-start;
  border: 1px solid #444;
  border-radius: 4px;
  //
  height: 28px;

  &::after {
    font-size: 0.9em;
    color: #444;
    padding: 6px;
    content: " ";
  }
}

.value-box {
  display: flex;
  align-items: flex-start;
  .select {
    flex-grow: 4;
  }
  .btn {
    width: 40px;
    margin-left: 8px;
  }
}

.numberIcon {
  font-weight: 900;
  margin-left: -2px;

  &::after {
    content: "123";
  }
}
