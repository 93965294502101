@import "../../../scss/noscroll";
@import "../../../scss/popover";

.select-errored {
  border-color: red !important;
}

.select-selection {
    @include noScrollbar;
  display: inline-flex;
  width: 90%;



  .select-selection-text {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
  }
}

.select-item-result {
  margin-left: 8px;
  margin-top: -5px;
}

.dropdown {
  cursor: pointer;

  .dropdown-menu {
    //background-color: deeppink;
  }
}

.dropdown-items-box {
  @include noScrollbar;

  display: block;
  max-height: 280px;
  overflow-y: scroll;
  z-index: 1000;
}




.ares-refbox-select-pop{
  .popover-header {
    background-color: rgb(86, 2, 122) !important;
    color: white !important;
    border-bottom: 1px solid darkviolet !important;
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: 600;
  }
}


.ares-refbox-select-pop {
  @include popover($color: darkviolet, $left: -15px, $top: 50%, $width: 450px, $minHeight:220px, $marginTop: 2px, $rotation: -90deg);
}