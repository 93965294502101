$elmWidth: 320px;

.reference {
  width: $elmWidth;
}

.cronRow{
  margin-top: 10px;
}

.label {
  margin-top: 10px;
  margin-bottom: 0px !important;
  font-variant: small-caps;
  font-size: 1.1em;
  color: #888;

  &:first-child {
    margin-top: 0px;
  }
}

.select {
  width: $elmWidth !important;
}

.settings {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .cell {
      display: block;
    }
  }
}

.scheduling {
  cursor: pointer;
  font-size: 0.9em;
}

.arrow {
  margin-right: 10px;
  margin-left: 10px;
  color: #888;
}

.resetScheduling {
  cursor: pointer;
  font-size: 0.8em;
  color: #444;

  &:hover {
    color: #888;
  }
}

.header {
  display: flex;
  font-size: 0.9em !important;
  .element {
    display: flex;
    margin-right: 10px;
    .label {
      font-size: 1em;
      &::after {
        content: ":";
      }
    }
    .value {
      margin-left: 5px;
    }
  }
}

.referenceOverride {
  margin-top: 10px;

  .icon {
    color: orange;
    margin-right: 5px;
  }
  .text {
    font-variant: small-caps;
  }
}

.overrideIcon {
  color: orange;
  font-size: 0.8em;
  margin-left: 5px;
}

.conversion {
  display: flex;
  align-items: center;

  .primary {
    cursor: pointer;
    font-size: 0.9em;
    margin-right: 10px;
  }

  .on {
    color: yellow;
  }

  .off {
    color: #888;
  }
}

.header {
  display: flex;
  align-items: center;
  font-size: 0.8em;

  .conversionLabel {
    display: flex;
    align-items: center;
    margin-right: 5px;

    border: 1px solid #888;
    border-radius: 6px;
    padding: 0px 6px;
    //color: #01013b;

    .icon {
      color: yellow;
      margin-right: 5px;
      font-size: 0.8em;
    }
  }
}

.variantBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      width: 50%;

      .labelCol {
        color: #888;
        font-variant: small-caps;
      }
      .contentCol {
        width: 466px;
      }
    }
  }
}

.variationHeader {
  display: flex;

  .variation {
    font-size: 0.8em;
    border: 1px solid #888;
    border-radius: 6px;
    padding: 0px 4px;
    margin-right: 5px;

    .icon {
      color: orange;
      margin-right: 5px;
    }
    .winnerIcon {
      color: yellow;
      margin-right: 5px;
      margin-left: 0px;
    }

    .trafficPerc {
      color: darkorchid;
    }

        .label {
          margin-left: 5px;
    
          &::after {
            content: ":";
            margin-right: 5px;
          }
        }

        .separator{
          margin-left: 5px;
          color:#888;
        }
    .score{
      .value{
        color: deepskyblue;
      }
    }
  }
}

.extra {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  .icon {
    color: green;
    margin-right: 3px;
    margin-left: 10px;
  }
  .traffic {
    font-weight: 700;
  }
  .trafficError {
    color: red !important;
  }
  .trafficOff {
    color: #444;
  }

  .isBase {
    font-size: 0.8em;
    font-variant: small-caps;
    padding: 0px 5px;
    border: 1px solid orange;
    border-radius: 5px;
    .icon {
      color: orange;
      margin-right: 5px;
      margin-left: 0px;
    }

    .label {
      color: #ccc;
    }
  }
}

.trafficKnob {
  margin-left: -10px;
}

.statsHead,
.trafficHead {
  display: flex;
  padding: 0px 0px 0px 6px;
  border: 1px solid darkorchid;
  border-radius: 6px;

  .timesCount {
    margin-left: 5px;
    font-size: 0.9em;
    color: #aaa;
  }
}

.statsHead{
  margin-left: 10px;
  border-color: darkturquoise;
}

.stuckUserSwitch {
  margin-top: 10px;
}

.titleWinnerIcon {
  font-size: 0.9em;
  color: yellow;
  margin-right: 5px;
}

.stuckBox {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .freeBtn {
    margin-left: 5px;
    height: 25px !important;

    .releaseIcon {
      margin-right: 5px;
      color: #666;
    }
  }
}

.analiticaIcon {
  font-size: 0.8em;
  color: deeppink;
  margin-right: 3px;
}

.cronString {
  color: #888;
}