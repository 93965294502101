.active{
    color: #FFF;
}

.activeSwitch{
    color: #006400;
}

.inactive{
    color:#222;
}

.extra{
    display: flex;
    justify-content: flex-end;

    .onOffBtn{
        margin-left: 10px;
    }
}

