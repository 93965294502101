@import "../../scss/noscroll";

$height: 700px;

.container{
    display: flex;
    flex-wrap: nowrap;
    min-width: 1500px;
    max-height: $height;

    .tables{
        @include noScrollbar;
        padding: 4px 6px 4px 6px;
        max-height: $height;
        width: 20%;
        min-width: 200px;
        background-color: #111;
        border-right: 2px solid #222;

        .table{
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            padding: 0px 6px 0px 6px;
            margin-bottom: 4px;

            &:hover{
                background-color: #222;
            }

            .total{
                font-size: 0.9em;
                padding: 0px 6px 0px 6px;
                background-color: #222;
                border-radius: 4px;
                
            }
            .active{
                margin-right: -6px;
                font-weight: 600;
                background: unset;
                background-color: #1a1a1a;
                border:0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                color:#fff;
            }

        }
        .active{
            font-weight: 400;
            border: 1px solid greenyellow;
            color: #fff;
            border-radius: 4px;
            &:hover {
                background-color: #444;
                //filter: drop-shadow(0 0 0.2rem deepskyblue);
            }
        }
    }

    .data{
        position: relative;
        max-height: $height;
        width: 85%;

        .table{
            max-height: $height;
        }
    }
}

.dataBox{
    padding: 6px;
    
}

.dataContainer{
    margin-bottom: 2px !important;

    &:last-child{
        margin-bottom: 0px;
    }
}
.dataTitle{
    display: flex;
    justify-content: flex-start;
}

.dataId{
    color:#888;
    font-size: 0.8em;
}

.volatileSwitch{
    margin-left: 20px;
}

.catalogBox{
    position: relative;
    z-index: 999;
    overflow-y: visible;
    margin-left: 20px;
}

.header{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.9em;

    .dataUsername{
        margin-right: 10px;
        color:#555;
    }

    .dataDate{
        color:#444;
    }
}
.search{
    position: sticky;
    z-index: 1000;
    display: flex;
    align-items: center;
    opacity: 0.8;
    padding: 6px 8px 6px 8px;
    background-color: #111;
    border-bottom: 2px solid #222;
    border-top: 1px solid #222;

    .label{
        color: #444;
        margin-left: 20px;

        &:first-child{
            margin-left: 0px;
        }

        &::after{
            content: ":";
            margin-right: 10px;
        }
    }

    .input{
        width: 366px;
    }

    .inputId{
        width: 150px;
    }
}