.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 100;
  color: #333;
  background: #222;
}

.bootstrap-switch.bootstrap-switch-focused {
  border-color: #444;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(6, 18, 27, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(13, 28, 41, 0.6);
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  background: blueviolet;
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: darkmagenta;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-compare,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-compare {
  background: rgb(1, 126, 61);
  //background: radial-gradient(circle, rgba(232, 57, 70, 1) 5%, rgba(0, 165, 80, 1) 95%);
  color: #fff;
}
