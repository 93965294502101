.item {
  display: inline-block;
  width: 250px;
  height: 200px;
  margin-left: 6px;
  vertical-align: top;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.item:hover {
  //transition: transform 0.3s ease-in-out;
  transform: scale(0.9);
  box-shadow: -1px 2px rgba($color: #000000, $alpha: 0.7);
  background: #000;
  padding: 2px;
}

.position {
  color: goldenrod;
  margin-right: 6px;
}

.thumb {
  width: 250px;
  height: 140px;
}

.thumb:hover {
  border: 3px solid rgba($color: #000000, $alpha: 0.8);
}

.container {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.container::-webkit-scrollbar {
  display: none;
}

.array {
  //height: 260px;
  //padding-top: 20px;
  float: left;
  overflow-y: hidden;
  overflow-x: scroll;
}

.array::-webkit-scrollbar {
  display: none;
}

.title {
  position: absolute;
  bottom: 0px;
  padding-left: 4px;
  //background-color: #000;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 900;
  color: #fff;

  backdrop-filter: blur(6px);
  opacity: 0.8;
}

.contentId {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  font-size: 0.8em;
  font-weight: 600;
  padding: 0px 4px;

  backdrop-filter: blur(6px);
}
.date {
  font-size: 0.8em;
  color: #555;
}
.category {
  font-size: 0.8em;
  color: #888;
}
.description {
  font-size: 0.8em;
  color: #888;
}

.infoBadge {
  font-size: 0.6em !important;
  font-variant: small-caps;
  font-weight: 900;
  vertical-align: middle;
}

.imageBox {
  position: relative;
}

.count,
.eye {
  color: #aaa;
  margin-right: 0px;
  margin-left: 10px;
}

.positionsBox {
  display: flex;
  margin-top: 10px;
  .subBox {
    width: 50%;
    margin-right: 10px;
    margin-bottom: 0px;

    &:last-child {
      margin-right: 0px;
    }
  }
}
