// .box {
//   padding-bottom: 1px;
// }

.container {
  //color: #00140e;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }

    // .spacer {
    //   flex-wrap: 1;
    //   background-color: red;
    //   &::after {
    //     content: " ciao mamma";
    //   }
    // }

    .btn {
      align-self: flex-end;
    }

    .label {
      margin-bottom: 6px;
      color: #888;
    }

    .content {
      width: 100%;
      flex-wrap: 4;
    }
  }
}
.header {
  align-self: center;
  .title {
    font-size: 1.1em;
  }
  .description {
    color: #888;
    font-size: 0.9em;
  }
}

// .doneBtn {
//   float: right;
// }
