.container {
    display: flex;

    .select {
        width: 366px;
    }

    .btn {
        margin-left: 6px;
    }
}