.error-message {
  cursor: pointer;
  color: #b00;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;

  &:last-child {
    margin-bottom: 0px;
  }
}
