.query {
  width: 100%;
}

.query-statement {
  margin-top: 15px;
  margin-bottom: 0px;
  padding: 16px;
  border: 1px solid #888;
  border-radius: 8px;
}

.query-statement:first-child {
  margin-top: 10px;
}

.statementLabel {
  font-weight: 900;
  border-bottom: 1px dotted;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-variant: small-caps;
  font-size: 1.4em;
}

h6 {
  font-weight: 400;
  font-size: 0.9em;
}

.link-to-rule {
  font-size: 0.9em;
  color: #444;
}

.link-to-rule:hover {
  color: #888;
}

.show-icon-advanced {
  float: right;
}

.advanced-topic {
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
}

.advanced-topic::after {
  color: #666;
  content: "|";
  margin-left: 16px;
}

.advanced-topic:last-child::after {
  content: "";
}

@mixin space {
  display: inline-block;
  padding: 8px;
  vertical-align: top;
  border: 1px solid #222;
  box-shadow: 0 0 20px #181818;
  border-radius: 8px;
  padding-top: 12px;
}

@mixin spaceLabel {
  font-size: 1.2em;
  font-variant: small-caps;
  font-weight: 900;
  color: #aaa;
  border-bottom: 1px dotted #444;
  margin-top: -10px;
  margin-bottom: 9px;
  padding-bottom: 2px;
}

@mixin spaceIcon {
  font-size: 0.7em;
  color: #aaa;
  margin-right: 4px;
}

.rules-presets-box {
  display: flex;

  .label {
    font-size: 1em;
    font-variant: small-caps;
    color: #888;
    .icon {
      font-size: 0.7em;
      margin-right: 6px;
    }
  }

  .rule-box-container {
    margin-bottom: 0px !important;
    width: 70%;

    .rules-label {
      @extend .label;
    }
  }

  .presets-box-container {
    width: 30%;

    .presets-label {
      @extend .label;
    }

    .presets-popup-box {
      display: flex;

      .presetSelect {
        display: inline-block;
        width: 377px;
        margin-right: 8px;
      }
      .presetsAddBtn {
        width: 60px;
      }
    }
  }

  .spacer {
    flex-grow: 4;
    margin-right: 4px;
    margin-left: 4px;
  }
}

// .rules-space {
//   @include space;

//   width: 69%;
//   margin-right: 10px;

//   .rules-label {
//     @include spaceLabel;

//     .icon {
//       @include spaceIcon;
//     }
//   }
// }

// .presets-space {
//   @include space;

//   width: 30%;
//   background: repeating-linear-gradient(-55deg, #070707, #070707 8px, #000 8px, #000 16px);
// }

// .presets-label {
//   @include spaceLabel;

//   .icon {
//     @include spaceIcon;
//   }
// }

.label-main {
  font-size: 0.9em;
  padding: 0px 6px 0px 6px;
  border-radius: 4px;
}

.preset-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  //padding: 8px;

  border: 1px solid goldenrod;
  border-radius: 8px;
  color: #bbb;
  background: repeating-linear-gradient(
    -55deg,
    rgb(36, 27, 5),
    rgb(36, 27, 5) 8px,
    #000 8px,
    #000 16px
  );

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 18px 2px 18px;

    .help {
      margin-left: 6px;
    }
    .spacer {
      flex-grow: 4;
    }
    .title {
      font-weight: 900;
    }
    .count {
      // display: inline-block;
      // position: absolute;
      // right: 36px;
      padding: 1px 8px 0px 8px;

      font-size: 0.7em;
      font-weight: 900;
      border-radius: 30%;
      background-color: gold;
      color: black;
    }
    .arrow {
      // display: inline-block;
      // position: absolute;
      // right: 8px;
      font-size: 0.8em;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .expand {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 2px;
    padding: 2px 10px 10px 10px;

    .ruleListContainer {
      margin-top: 0px;
      margin-bottom: 10px;
      padding: 8px;
      border: 1px solid darkgoldenrod;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.432);

      .rule {
        padding: 0px;
        margin: 0px;
        .title {
          font-size: 0.9em;
          font-variant: small-caps;
          color: #fff;
        }

        .override {
          color: #666;
          text-decoration: line-through;
        }
      }
    }

    .delete {
      width: 100%;
      text-align: right;

      .delBtn {
        background-color: rgb(104, 75, 3) !important;
        border-color: darkgoldenrod;
        font-size: 0.8em;
      }

      .icon {
        margin-right: 4px;
      }
    }
  }
}
.preset-container:last-child {
  //margin-bottom: 0px;
}
.query-presets-container {
  margin-top: 6px;
  //border-top: 1px solid #444;
  padding-top: 5px;
  padding-bottom: 5px;
  //margin-top: 10px;
}

@media only screen and (max-width: 1660px) {
  .addLabel {
    display: none;
  }
  .preset-container .expand .delete .icon {
    margin-right: 0px;
  }
}

.query-rules-container {
  cursor: pointer;
  margin-top: 10px;
  //margin-bottom: 10px;
  // padding-bottom: 5px;
  padding: 0;
  min-height: 5px;

  // Vertical Spacing
  .query-rule:first-child {
    // margin-top: 6px;
    margin-bottom: 6px;
  }
  .query-rule + .query-rule {
    // margin-top: 6px;
    margin-bottom: 6px;
  }
  .query-rule:last-child {
    // margin-top: 6px;
    margin-bottom: 0px;
  }

  .compactRule {
    padding: 8px !important;
  }
  .query-rule {
    display: inline-block;
    vertical-align: top;
    display: inline-block;
    padding: 6px 9px 6px 9px;
    border: 1px solid #888;
    border-radius: 8px;
    margin-right: 8px;

    .query-rule-header {
      display: block;
      width: 100%;

      .query-rule-id {
        font-weight: 700;
      }
      .query-rule-expander {
        float: right;
        margin-left: 10px;
      }
    }

    .query-rule-body {
      min-width: 400px;
      min-height: 60px;
      //border-top: 1px solid #aaa;
      padding-top: 2px;

      margin-top: 6px;

      .query-rule-description {
        font-size: 0.9em;
        color: #aaa;
        margin-top: 8px;
        word-wrap: break-word;
      }

      .query-rule-override {
        background: #111;
        border: 1px dotted #666;
        border-radius: 8px;
        padding: 14px;
        margin: 2px;
        margin-top: 12px;

        .form-group {
          margin-bottom: 10px;
        }

        .query-rule-override-label {
          font-weight: 500;
          border-bottom: 1px solid #aaa;
          padding-bottom: 4px;
          margin-bottom: 4px;
        }

        .query-rule-override-condition {
          color: #aaa;
          font-size: 0.9em;
        }
      }
    }

    .query-rule-footer {
      text-align: right;
      display: block;
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: 12px;
      //border-top: 1px solid #aaa;
      //color:#03110f
    }
  }
}

.rule-label {
  font-variant: small-caps;
  color: #888;
}
.rule-param-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .spacer {
    flex-grow: 4;
  }
}
.rule-param-row:last-child {
  margin-bottom: 0px;
}

// .query-filter {
//   border-color: forestgreen; //#00825a
//   border-width: 2px;
//   color: forestgreen;
//   box-shadow: 0 0 10px forestgreen;

//   .query-rule.compactRule {
//     border-color: forestgreen;
//   }

//   .query-rule:not(.compactRule) {
//     width: 100% !important;
//     border-color: forestgreen;
//     background: linear-gradient(to bottom, forestgreen 0%, #01583e 36px, #111 34px);
//     color: #eee;
//   }
// }

// .query-exclude {
//   border-color: #e63228;
//   border-width: 2px;
//   color: #e63228;
//   box-shadow: 0 0 10px #e63228;

//   .query-rule.compactRule {
//     border-color: #e63228;
//   }

//   .query-rule:not(.compactRule) {
//     width: 100% !important;
//     border-color: #e63228;
//     background: linear-gradient(to bottom, #e63228 0%, #94201a 36px, #111 34px);
//     color: #eee;
//   }
// }

// .query-boost {
//   border-color: darkviolet;
//   border-width: 2px;
//   color: darkviolet;
//   box-shadow: 0 0 10px darkviolet;

//   .query-rule.compactRule {
//     border-color: darkviolet;
//   }

//   .query-rule:not(.compactRule) {
//     width: 100% !important;
//     border-color: darkviolet;
//     background: linear-gradient(to bottom, darkviolet 0%, #6c0577 36px, #111 34px);
//     color: #eee;
//   }
// }

// #f0c800
// .query-include {
//   border-color: darkturquoise;
//   border-width: 2px;
//   color: darkturquoise;
//   box-shadow: 0 0 10px darkturquoise;

//   .query-rule-expander,
//   .query-rule-id {
//     color: #111;
//   }
//   .query-rule.compactRule {
//     border-color: darkturquoise;

//     .query-rule-expander,
//     .query-rule-id {
//       color: darkturquoise;
//     }
//   }

//   .query-rule:not(.compactRule) {
//     width: 100% !important;
//     border-color: darkturquoise;
//     background: linear-gradient(to bottom, darkturquoise 0%, rgb(38, 131, 122) 36px, #111 34px);
//     color: #eee;
//   }
// }

.query-order {
  border-color: #1450aa;
  border-width: 2px;
  color: #1450aa;
  box-shadow: 0 0 10px #1450aa;

  .query-rule.compactRule {
    border-color: #1450aa;
  }

  .query-rule:not(.compactRule) {
    width: 100% !important;
    border-color: #1450aa;
    background: linear-gradient(to bottom, #1450aa 0%, #0c3674 36px, #111 34px);
    color: #eee;
  }
}

.link-to-clean {
  font-size: 0.9em;
  font-variant: small-caps;
  color: #aaa;
  margin-right: 16px;
}

.link-to-clean:hover {
  color: #fff;
}

.rule-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .badge {
    font-size: 0.7em;
    font-weight: 700;
    border: 1px solid #222;
    border-radius: 6px;
    padding: 1px 8px 0px 8px;
    margin-right: 10px;
    text-transform: uppercase;
    background-color: #222;
    border-color: #444;
    color: #fff;
  }

  .rule-header-if {
    @extend .badge;
  }
  .rule-header-then {
    @extend .badge;
    margin-left: 10px;
  }
}

.sort-override {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;

  .sort-override-label {
    font-variant: small-caps;
    color: #888;
    margin-right: 6px;
  }

  .sort-override-select {
    width: 150px;
  }
}

.range-override {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  margin-left: 20px;

  .range-override-select {
    width: 150px;
  }
}

.not-overwrite-warning {
  width: 115px;
  font-size: 0.9em;
  font-variant: small-caps;
  //text-transform: uppercase;
  color: goldenrod;

  .icon {
    margin-right: 4px;
    color: orange;
  }
}

.settings-header {
  display: flex;
  align-items: center;

  .label {
    font-size: 0.8em;
    color: #888;
    text-transform: uppercase;
    margin-right: 4px;
    margin-left: 18px;
    &:first-child {
      margin-left: 0px;
    }
    &::after {
      content: ":";
    }
  }
  .catalog {
    padding: 0px 4px 1px 4px;
    border: 1px solid #666;
    border-radius: 6px;
    font-size: 0.8em;
    font-variant: small-caps;
    margin-left: 4px;
    color: #eee;
    cursor: pointer;
  }

  .multiplier {
    color: #888;
    font-variant: small-caps;

    &::before {
      content: "|";
      color: #333;
      margin-left: 6px;
      margin-right: 6px;
    }
    &::after {
      content: ":";
      margin-right: 6px;
    }
  }

  .value {
    background-color: #333;
    padding: 0px 6px;
    border-radius: 5px;
    font-size: 0.8em;
  }
}

.rules-count {
  display: flex;
  align-items: center;

  .count {
    font-size: 0.9em;
    background-color: rgb(12, 27, 0);
    border-radius: 6px;
    color: rgb(224, 223, 223);
    padding: 0px 6px 0px 6px;
  }
  .label {
    margin-left: 6px;
    margin-right: 10px;
    color: #999;
    font-variant: small-caps;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.rule-id {
  font-size: 0.9em;
  color: #888;
}
