.title {
  display: flex;
  align-items: center;

  .smartIcon {
    font-size: 0.9em;
    margin-right: 8px;

    .pinned {
      color: darkmagenta;
    }

    .smart {
      color: greenyellow;
    }

    .defaultPin {
      color: #666;
    }
  }
  .titleLabel {
  }

  .copyIcon,
  .idLabel,
  .id {
    font-size: 0.8em;
    color: #666;
    margin-left: 6px;
  }

  .copyIcon {
    color: #222;
    margin-left: 6px;
  }

  .id::after {
    content: ":";
  }
  .id:hover {
    color: #aaa;
    //color:#0c2217
  }

  .copyIcon:hover {
    color: #444;
    //color: #020f0c;
  }
}

.mainExtra {
  display: flex;
  justify-content: center;
  align-items: center;
  .extraBadge {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
    border-radius: 5px;
    padding: 0px 4px;
    margin-left: 10px;
    font-size: 0.8em;
    color: #bbb;
  }
}

.boxExtra {
  display: flex;
  align-items: center;
  .label {
    margin-left: 6px;
    font-size: 0.9em;
    padding: 0px 6px 0px 6px;
    border-radius: 4px;
  }
}

.boxHeader {
  display: flex;
  justify-content: center;
  align-items: center;

  .label {
    margin-right: 6px;
    font-variant: small-caps;
    color: #888;

    &::after {
      content: ":";
    }
  }

  .badge {
    text-transform: uppercase;
    font-size: 0.8em;
    color: #aaa;
    padding: 0px 4px;
    border-radius: 5px;
    border: 1px solid #444;
    margin-right: 10px;
  }
}

.subBlockHeader {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  // .spacer {
  //   //flex-basis: 20px;
  //   flex-grow: 10;
  // }
}

.placeHolderChar {
  width: 30px;
  display: inline-block;
  margin-right: 8px;
}

.placeHolderCharPosition {
  width: 150px;
  display: inline-block;
}

.injectBox {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  align-content: space-between;
  margin-top: -6px;
}

.generalHeader {
  display: flex;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    .label {
      color: #888;
      font-variant: small-caps;

      &::after {
        content: ":";
        margin-right: 6px;
      }
    }

    .value {
      font-size: 0.8em;
      text-transform: uppercase;
    }

    &::after {
      color: #333;
      content: "|" !important;
      margin-right: 8px;
      margin-left: 8px;
    }

    &:last-child::after {
      content: "" !important;
    }
  }
}

.responseParams {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;

  .knobs {
    display: flex;

    & > * {
      margin-right: 10px;
    }
  }
  .switches {
    margin-left: 22px;
  }
}

.injectsHeader {
  text-transform: uppercase;
  font-size: 0.7em;
  padding: 1px 6px 0px 6px;
  background-color: #556b2f;
  border-radius: 5px;

  span {
    margin-left: 3px;
    color: #000;
    font-weight: 900;
  }
}

.filtersCleaningBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scheduledBlockIcon {
  font-size: 0.8em;
  color: goldenrod;
  margin-left: 6px;
}

.rotaryStartFromSmart {
  margin-left: 0px !important;
}

.colWithLink {
  display: flex;
  align-items: center;

  .btnLink {
    margin-left: 6px;
  }
}

.switchTitle {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 110px;
}

.packChooserExtra {
  display: flex;
  margin-right: -10px !important;

  .mode {
    margin-right: 10px;
  }

  .packModePositionalInput {
    .idExtractPosition {
      color: orange;
      width: 50px;
      margin-left: 5px;
      border-radius: 2px;
    }
  }
}
.repeatedBox{
  display: flex;
}