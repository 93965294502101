.header {
  display: flex;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  //margin-bottom: 10px;

  .title {
    display: flex;
    align-items: center;
    font-size: 1.3em;
    color: #666;

    .id {
      margin-left: 4px;
      font-size: 0.8em !important;
      color: #aaa;
      text-transform: uppercase;
    }
  }
  .spacer {
    flex-grow: 4;
  }

  .arrow {
    float: right;
    font-size: 1.4em;
    color: #444;
  }
}

.extended {
  margin-top: -10px;

  .addSnap {
    .label {
      font-variant: small-caps;
      margin-right: 6px;
      font-size: 1.1em;
    }
  }

  .tabIcon {
    margin-right: 6px;
  }

  .tabDeleteIcon {
    font-size: 0.8em;
    margin-left: 6px;
    color: red;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }

  .snapNoActive {
    color: orange;
  }

  .snapOK {
    color: green;
  }

  .snapKO {
    color: red;
  }

  .snapBox {
    margin-top: 10px;
    margin-left: 10px;
    .row {
      margin-bottom: 10px;

      .label {
        font-variant: small-caps;
        font-size: 1.1em;
        color: #888;
      }

      .value {
        cursor: pointer;
        margin-left: 6px;
        margin-right: 14px;
        border: 1px solid #222;
        color: #fff;
        border-radius: 6px;
        padding: 2px 8px 2px 8px;
        font-size: 0.9em;
        //font-variant: small-caps;

        &:hover {
          border-color: #aaa;
        }
      }

      .element {
        display: inline-block;
      }
    }
  }
  .footer {
    margin-top: 10px;
    height: 30px;
    .snapBtn {
      float: right;

      .icon {
        margin-right: 6px;
      }
    }
  }
}

.healthIcon {
  align-self: center;
  font-size: 1em;
  color: red;
  margin-right: 8px;
  animation: pulse 0.5s infinite;
  animation-direction: alternate;
  //color: #240000;
}

.compact {
  display: flex;
  cursor: pointer;
  align-items: baseline;

  .label {
    font-size: 1.2em;
    font-variant: small-caps;
    color: #888;
    margin-right: 4px;

    &::after {
      content: ": ";
    }
  }

  .value {
    font-size: 1em;
    font-weight: 900;
    color: #bbb;
    margin-right: 20px;

    &:last-child {
      margin-right: 0px;
    }

    .tbd {
      color: red;
    }
  }
}
