.box {
  display: block;
  width: 95%;
  z-index: 9999999;

  padding: 6px;
  margin-left: 10px;
  margin-bottom: -6px;
  margin-top: -2px;
  cursor: pointer;
}

.label {
  display: inline-block;
  width: 99%;
  font-size: 0.9em;
  font-variant: small-caps;
  color: #eee;
}
