.pageTab {
  .icon {
    margin-right: 5px;
    color: deepskyblue;
  }

  .iconLab {
    color: greenyellow !important;
  }

  .iconAnalitica {
    color: deeppink !important;
  }

  .label {
    color: #eee;
  }
}

.tabContainer {
  margin-top: 10px;
}

.filtersBox {
  .selectBox {
    display: flex;
    width: 100%;

    .packSelect {
      width: 400px;
    }
    .packBtn {
      margin-left: 5px;
      max-height: 27px;
      margin-left: 10px;
    }
  }
}

.packTitle {
  font-size: 0.9em;
  margin-right: 10px;

  .packIcon {
    margin-right: 5px;
  }
}

.deleteRuleIcon {
  font-size: 0.8em;
  margin-left: 0px;
  color: #444;

  &:hover {
    color: red;
  }
}

.label {
  margin-top: 10px;
  &:first-child {
    margin-top: 0px;
  }
}

.packs {
  display: flex;
  margin-top: 10px;
}

.header {
  .packContainer {
    max-height: 28px;
    margin-bottom: 0px;
  }
}

.extra {
  display: flex;
  align-items: baseline;

  .extraText {
    margin-right: 5px;
    font-variant: small-caps;
    color: #888;
  }
  .maxFilterPercentage {
    width: 45px;
    color: #ff8c00;
    padding: 0px 4px;
    font-size: 0.9em;
    border-radius: 5px;
    text-align: center;
  }
}

.clessidra{
  background-color: #000;
  color: #fff;
}