.isPrimary {
  font-size: 0.8em;
  font-variant: small-caps;
  padding: 0px 5px;
  border: 1px solid yellow;
  border-radius: 6px;

  .icon {
    font-size: 0.9em;
    color: yellow;
    margin-right: 5px;
  }

  .label {
    color: #aaa;
  }
}

.conversionHeader {
  display: flex;

  .result {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    margin-right: 10px;

    .positive {
      color: green;
    }

    .negative {
      color: red;
    }

    .positiveBack {
      background-color: green;
    }

    .negativeBack {
      background-color: red;
    }

    .block {
      margin-right: 5px;
      padding: 0px 6px;
      border-radius: 4px;
      font-size: 0.9em;
      color: black;
    }
  }
}

.chooserHeader {
  display: flex;

  .hypothesis,
  .conversions,
  .interval,
  .range {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .intervalSelect {
    width: 100px;
    height: 25px !important;
  }
  .label {
    color: #888;
    font-variant: small-caps;
    margin-left: 10px;
    margin-right: 5px;

    &::after {
      content: ":";
    }
  }

  .scheduling {
    cursor: pointer;
    font-size: 0.9em;
  }

  .arrow {
    margin-right: 10px;
    margin-left: 10px;
    color: #888;
  }
}

.extra{
  display: flex;
  align-items: baseline;

  .label{
    margin-right: 5px;
    color: #888;
    font-variant: small-caps;

    &::after {
      content: ":";
    }
  }
}