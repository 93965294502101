// .container {
//   cursor: pointer;
//   width: 100%;
//   height: 100vh;
// }

.btn {
  width: 60px;
  opacity: 0.7;
  margin-bottom: 2px;
}

.plusMinusBox {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
  opacity: 0.7;
}

.btn-lg {
  font-size: 12px;
  line-height: 1;
  padding: 4px;
}
.btn-zoom {
  font-weight: 900;
  width: 30px;
  //   font-size: 14px !important;
  //   width: 20px;
}
.btn-bottom {
  width: 30px;
  //font-size: 18px !important;
  font-weight: 900;
  //   width: 20px;
}
.description {
  font-size: 12px;
  margin-right: 0.25rem;
}
.controls {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.mini-map {
  position: absolute;
  bottom: 30px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.miniMapBtn {
}
.relative {
  position: relative;
}

.rotaryBox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tooltip {
  background-color: #222 !important;
  color: white !important;
  border: 2px solid #444 !important;

  .tooltipBox {
    min-width: 350px;
    display: flex;
    flex-direction: column;
    font-family: Menlo, "Courier New", Courier, monospace;
    font-size: 0.9em;
    // background-color: #000;
    // color: #fff;
    .lastSaved {
      margin-top: 5px;
      padding-top: 7px;
      border-top: 1px dotted #000;
    }
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 3px;

      .label {
        font-weight: 600;
        margin-right: 5px;
        color: darksalmon;
        &::after {
          content: ":";
        }
      }
      .value {
        display: flex;
        .fallbackIcon {
          color: deeppink;
        }
        .metadata {
          background-color: rgb(151, 12, 86);
          color: white;
          padding: 2px 6px;
          border-radius: 5px;
          margin-right: 3px;
        }
      }
    }
  }
}
