.label {
  width: 250px;
}

.labelDimmed{
  width: 250px;
  color: #888 !important;

  outline: none;
  pointer-events: none;
  user-select: none;
     
}

.scope {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  .controls{
    display: flex;
    outline: none;
      // pointer-events: none;
      // user-select: none;
    & > *{ margin-right: 10px;}
  }
}

.conversionLabel {
  cursor: pointer;
  padding: 0px 6px;
  border: 1px solid deeppink;
  border-radius: 5px;
  font-size: 1em;
  color: #aaa;
}

.scopeLabel{

  -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 4px 6px;
  border: 1px solid;
  border-radius: 5px;
  font-size: 1em;
  color: #aaa;

  .includes {
      font-size: 0.9em;
      font-variant: small-caps;
      margin-left: 10px;
  }

  .divider{
    flex-grow: 4;
  }
}


.scopeLabelBlock {
  border-color: blueviolet;

 
}

.scopeLabelItem {
  border-color: darkmagenta;
}

.paramsBox {
  margin-top: 10px;
}

.fieldLabel {
  margin-top: 10px;
  &:first-child {
    margin-top: 0px;
  }
}

.titleIcon {
  margin-right: 5px;
  font-size: 0.9em;
  color: rgb(124, 218, 250);
}

.lockIcon {
  color: #888;
}

.OK{
  color: green;
}

.KO{
  color:#444;
}

.whiteContainer{
  margin-top: 10px;
}