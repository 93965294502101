.dropdown-item {
  cursor: pointer;
  padding-bottom: 0px !important;
}

.dropdown-header{
  color: #444 !important;
  padding-bottom: 2px !important;
  padding-top: 0px !important;
  padding-left: 10px !important;
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  border-bottom: 1px dotted #444;
  &:first-child{
    margin-top: 0px !important;
  }

}
.logo-main {
  font-family: "menlo", Courier, monospace !important;
  letter-spacing: "-2px";
}

.user-picture {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 6px;
}
.nav-link.active {
  font-weight: 600;
}

.content-pane {
  margin: auto;
  // margin-top: 60px;
  // max-width: 98%;
  padding: 41px 1px 10px 1px;
  //padding-bottom: 100px;
}

.navbar {
  border-bottom: 1px solid #222;
  height: 40px;
  font-weight: 100;
  font-size: 1em;
}
.navbar-dark {
  background: #000 !important;
}
.modal-dialog {
  color: black;
}

.loading-container {
  font-family: "menlo", courier;
  height: 400px;
  width: 500px;
  margin: auto;
  margin-top: 5%;
}

.fullscreen-dimmed-container {
  font-family: "menlo", courier;
  margin: auto;
  z-index: 9999;
  display: block;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  padding-top: 10%;
  text-align: center;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;

  div {
    margin: auto;
  }
}

.user-name {
  letter-spacing: -1px;
  font-weight: 500;
}

.user-tenant {
  margin-left: 8px;
  margin-right: 4px;
  color: #000;
  border-radius: 2px;
  padding: 0px 6px 2px 6px;
  font-variant: small-caps;
  font-weight: 900;
  font-size: 1.1em;
}

.nav-toggle-button {
  font-size: 0.7em;
  margin: 0;
}

.navbar-collapse {
  // height: 16px;
  //background: #000;
}

.menuIcon {
  margin-right: 8px;
}

.menu-label-item {
  margin-right: 6px;
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  margin-right: 10px;
  margin-left: 10px;

  //border-left: 1px dotted #222;
  border-right: 1px dotted #222;

  .loggedUsers {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    padding: 0px 6px;
    border-radius: 5px;
    background-color: #212121;
    color: #888;

    &:hover {
      background-color: #444;
      color: #fff;
    }
    .userIcon {
      margin-right: 6px;
    }
  }
}

.arrowAfter {
  top: 20px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-right-color: transparent !important;
  margin-top: 0px !important;
}

$dataPaneColor: yellowgreen;
$userPaneColor: deepskyblue;

// .ares-data-panel > .arrow::after {
//   @extend .arrowAfter;
//   border-bottom-color: $dataPaneColor !important;
// }

// .ares-data-panel > .arrow::before {
//   @extend .arrow::after;
// }

@import "../../scss/popover";
.ares-data-panel {
  @include popover($color: $dataPaneColor, $marginTop: 0px);
}

// .ares-logged-panel > .arrow::after {
//   @extend .arrowAfter;
//   border-bottom-color: $userPaneColor !important;
// }

// .ares-logged-panel > .arrow::before {
//   @extend .arrow::after;
// }

.ares-logged-panel {
  @include popover($color: $userPaneColor, $marginTop: 10px);
}

.signal-pane {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 0.9em;

  .OK {
    color: green;
  }
  .KK {
    color: darkgoldenrod;
  }
  .KO {
    color: red;
  }

  .API-VERSION {
    display: inline-block;
    margin-left: 6px;
    font-size: 0.8em;
    color: #666;
  }
  button {
    margin-left: 10px;
    padding: 0px 6px !important;
  }
}

.sharedForm {
  filter: invert(100%);
}
