@import "../../../../../scss/noscroll";



.tooltip {
  padding: 6px;
  min-width: 200px;
  //max-height: 300px;
  // overflow-y: scroll;
  text-align: left;
  ul {
    margin-top: 2px;
    margin-left: -25px !important;
    margin-bottom: 0px !important;
    list-style-type: square;
    list-style-position: outside;

    li {
      font-family: "Menlo", "Courier New", Courier, monospace;
      font-size: 0.9em;
      color: #ddd;
    }
  }
}

.conversionTitle {
  font-variant: small-caps;
  font-size: 1.1em;
  font-weight: 500;
  color: deeppink;
  //color: #2a3241;
}
.conversionsBox {
  .conversionBox {
    .timeline {
      position: relative;

      .header {
        display: flex;
        justify-content: space-between;
      }

      .users {
        display: inline-block;

        .usersIcon {
          color: deepskyblue;
          margin-left: 10px;
          margin-right: 5px;
          font-size: 1.1em;
        }

        .usersText {
          font-weight: 600;
        }
      }

      .switch {
        height: 25px;
        margin-bottom: 0px;
      }
    }
  }
}

.searchesVirtuoso {
  @include noScrollbar;
}

.searchesBox {
  .data {
    // display: flex;
    // flex-direction: column;
    position: relative;
    height: 40vh;
    overflow-y: scroll;
    @include noScrollbar;
  }

  .extra {
    display: flex;
    font-size: 0.9em;

    .elm {
      display: flex;
      align-items: center;
      justify-self: flex-end;
      margin-left: 10px;
      width: 120px;
      color: #888;

      .icon {
        margin-right: 5px;
      }

      // .user {
      //   color: #888;
      // }
      // .search {
      //   color: deeppink;
      // }
      // .average {
      //   color: deepskyblue;
      // }
    }

    .percentages {
      display: flex;
      align-items: baseline;
      min-width: 100px;
      margin-right: 10px;
      font-size: 0.8em;
      font-family: "Menlo", "Courier New", Courier, monospace;
      $radius: 4px;

      .single {
        width: 50%;
        background-color: #013052;
        text-align: center;
        padding: 0px 4px;
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
      }
      .progressive {
        width: 50%;
        text-align: center;
        background-color: #123601;
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
      }
    }
  }

  .titleBox {
    display: flex;
    align-items: center;
    font-size: 0.9em;

    .titleIcon {
      font-size: 0.8em;
      color: #444;
      margin-right: 2px;
    }

    .titlePosition {
      font-size: 0.9em;
      color: #666;
    }

    .queryTitle {
      display: flex;
      align-items: baseline;
      margin-left: 5px;
      min-width: 200px; //65vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .synonymsCount {
        cursor: pointer;
        margin-left: 5px;
        font-size: 0.8em;
        padding: 0px 14px;
        height: 20px;
        border-radius: 4px;
        background-color: rgb(37, 37, 37);
      }
    }
  }
}

.catalogsBox {
  margin-top: 10px;
}

.positionsBox {
  display: flex;

  .items {
    width: 50%;
    margin-right: 10px;
  }

  .blocks {
    width: 50%;
  }
}

.emptyResponse {
  color: red !important;
}

.countIcon {
  color: deeppink;
}

.searchTitle {
  display: flex;
  align-items: baseline;

  .count {
    margin-left: 10px;
    font-size: 0.8em;
    background-color: darkslateblue;
    padding: 0px 6px;
    border-radius: 6px;
  }
}
