.bagOfIdsContainer {
  margin-top: 10px;
}

.startEnd {
  .label {
    margin-top: 10px;
  }
  .input {
    width: 150px;
  }
}

.checkLabel{
  color: whitesmoke;
}