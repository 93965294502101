.container {
  display: flex;

  .field {
    width: 50%;
  }

  .external {
    width: 50%;
  }
}
