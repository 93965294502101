.rule-container {
  padding: 4px;
  //padding-top: 15px;
  padding-bottom: 0px;
  border: 1px solid #444;
  border-radius: 8px;
  //background-color: #161616;
}

.rule-container:nth-child(n + 1) {
  margin-top: 15px;
}

.execute-container {
  background-color: pink;
  margin-top: 20px;
  //padding: 12px;
  border: 1px solid #333;
  border-radius: 8px;

  .execute-header {
    height: 48px;
    border-bottom: 1px solid #333;
    padding-bottom: 0px;
    padding-top: 10px;
    margin-bottom: 10px;
    .execute-title {
      font-size: 1.3em;
      font-weight: 900;
      color: #aaa;
    }
  }
}

.execute-panel {
  padding: 12px;
  // border: 1px solid #333;
  // border-radius: 8px;

  .execute-header {
    height: 38px;
    border-bottom: 1px solid #333;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-bottom: 10px;
    .execute-title {
      font-weight: 400;
      font-size: 1.1em;
      color: #fff;

      &::before {
        content: "|";
        margin-left: 0px;
        margin-right: 6px;
        font-weight: 100;
        color: green;
      }
    }
  }
}

.extra-panels {
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-top: 10px;

  .multi-row {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;

    .row>* {
        padding-left: 0px !important;
      }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //flex-grow: 1;
      //max-width: 100%;

      margin: 0px !important;
      margin-bottom: 30px !important;

      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }

  .column {
    flex-basis: 32%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .label {
      width: 100%;
      text-align: left;
      margin-left: 12px;
      margin-right: 12px;
      font-variant: small-caps;
      color: #888;
    }
    .content {
      width: 100%;
      //flex-grow: 5;
    }
  }
}

.execute-box {
  // display: block;
  // padding: 12px;
  padding-bottom: 0px;
  // border: 1px solid #333;
  // border-radius: 8px;
  margin: auto;
  width: 100%;

  .rule-type-select {
    width: 190px;
    select {
      background-color: #111 !important;
      border-color: #111 !important;

      &:hover {
        background-color: #222 !important;
        border-color: #333 !important;
      }
    }
  }

  .outerFieldHeader {
    .label {
      font-variant: small-caps;
      color: #888;
      margin-left: 14px;

      &:first-child {
        margin-left: 0px;
      }
      &::after {
        content: ":";
        margin-right: 6px;
      }
    }

    .badge {
      border: 1px solid #111;
      border-radius: 6px;
      padding: 1px 6px 1px 6px;
      font-size: 0.9em;
    }
    .id {
      @extend .badge;
      background-color: #333;
    }
    .type {
      @extend .badge;
      background-color: darkslategrey;
    }
    .dbType {
      @extend .badge;
      background-color: darkolivegreen;
    }

    .nested {
      @extend .badge;
      background-color: darkslateblue;
    }
  }

  .main-statement {
    //padding: 12px;
    padding-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .outer-field {
      flex-basis: 100%;
      margin-right: 10px;
      .fieldLabel {
        background-color: #222 !important;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1px solid #444;
        border-radius: 4px;
        height: 31px;
        padding-top: 2px;
        padding-left: 8px;
        font-size: 1em;
      }
    }
    .operator-select {
      flex-basis: 100%;
      margin-right: 10px;
    }
    .values {
      flex-basis: 100%;
    }

    .boost {
      flex-basis: 400px;
      align-self: baseline;
      display: flex;
      align-items: baseline;
      padding-left: 6px;
      //margin-right: 10px;
      .list-link {
        //margin-left: 8px;
        margin-right: 12px;
      }
    }
  }
  .error-box {
    display: flex;
    .spacer {
      flex-basis: 33%;
      margin-right: 10px;
    }
    .error {
    }
  }
}

.rule-stop {
  float: right;
}

.alignRight {
  text-align: right;
  font-weight: 600;
}

.typeLabel {
  color: #888;
  font-variant: small-caps;
  margin-bottom: 1px;
}

.extra-controls {
  //color: #021302;
  width: 100%;
  margin: auto;
  padding: 16px;
  padding-bottom: 0px;
  border: 1px solid #000;
  border-radius: 8px;
  box-shadow: 0 0 40px #000 inset;
  //box-shadow: 0 0 10px #222;
  background: repeating-linear-gradient(-55deg, #101, #101 8px, #000 8px, #000 16px);
}

.avoid-params-ovr {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 30%;
}

.delete-rule-btn-row {
  margin-top: 10px;
  margin-bottom: 0px;
  .delete-icon {
    margin-right: 4px;
  }
}
