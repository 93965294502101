.label-container {
  padding: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #444;
  border-radius: 6px;
  font-size: 0.9em;
  font-variant: small-caps;
  font-weight: 400;
  max-width: 100%;
  min-height: 30px;
  background-color: #222;
}

.label-container:hover {
  // color: #fff;
  border: 1px solid #fff;
  //box-shadow: 0 0 16px border;
}
