.explorer-container {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 80vh;

  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.fullscreen-enabled .explorer-container {
  position: relative;
  cursor: pointer;
  width: 100vw !important;
  height: 103vh !important;

  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
