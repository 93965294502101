@import "../../../scss/noscroll";

.select-no-results {
  display: inline-block;
  margin-left: 35px;
  font-size: 0.9em;
  color: #eee;
}

.drop-header {
  margin-bottom: -10px;
}

.drop-divider {
  //border-bottom: 1px solid #222;
  padding-top: 0px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.drop-divider:last-child {
  border-bottom: 0px;
}

.select-item-result {
  margin-left: 22px !important;
}

.sub-meta-list {
  @include noScrollbar;
}

.group-items-count-badge {
  font-size: 0.9em;
  margin-left: 4px;
  color: #aaa;
  padding: 1px 6px 1px 6px;
  background-color: #222;
  border: 1px solid #333;
  border-radius: 6px;
}

.group-header {
  //background-color: rebeccapurple;
  padding-bottom: 12px !important;
}
