.block-container {
  cursor: pointer;
  padding: 16px;
  border: 1px solid #888;
  border-radius: 8px;
  background-color: #111;

  .form-group {
    margin-bottom: 4px;
  }
}

.block-container:nth-child(n + 1) {
  margin-top: 15px;
}

.show-icon-container {
  width: 100%;
  text-align: right;
  height: 14px;
}

.block-type-label {
  display: inline-block;
  margin-right: 20px;
  font-size: 1.2em;
  font-variant: small-caps;
  font-weight: 900;
  color: #888;
}

.warning {
  color: #888 !important;
}
@mixin block-type {
  margin-top: 20px;
  border: 1px solid;
  border-radius: 8px;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 15px;
}
.block-type-single {
  @include block-type;
  border-color: #444;
  border-style: dotted;
  box-shadow: 0 0 12px #333;
  background-color: #111;
}
.block-type-multiple {
  @include block-type;
  border-color: darkviolet;
  box-shadow: 0 0 12px darkviolet;
}

.ui-box {
  margin-top: 20px;
  padding: 16px;
  border: 1px solid #222;
  border-radius: 8px;
  box-shadow: 0 0 8px #888;
  background-color: rgba($color: #000000, $alpha: 0.4);

  .ui-title {
    font-size: 1.3em;
    font-variant: small-caps;
    // border-bottom: 1px dotted #444;
    // padding-bottom: 2px;
    margin-bottom: 15px;
  }
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-single,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-single {
  background: #444;
  color: #eee;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-multiple,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-multiple {
  background: darkviolet;
  color: #fff;
}

.block-conditions {
  margin-top: 12px;
}

.sub-title {
  width: 466px;
  display: inline-block;
  //margin-left: 6px;
}

.sub-title-btn {
  display: inline-block;
  margin-left: 6px;
}

.sub-title-closed {
  display: inline-block;
  color: #888;
  font-variant: small-caps;
  font-size: 1.1em;
}

.sub-title-edit {
  display: inline-block;
  margin-left: 6px;
  color: #888;
}

.sub-title-edit:hover {
  color: #fff;
}

.conditions-container {
  //padding-top: 10px;
  padding-bottom: 10px;
  //background-color: #212121;
}
.results-container {
  display: block;
  padding: 16px;
  border: 1px solid #333;
  border-radius: 8px;
  background-color: #000;
  margin-left: 0px;
  margin-bottom: 16px;

  .results-header {
    height: 38px;
    border-bottom: 1px solid #333;
    padding-bottom: 0px;
    margin-bottom: 22px;

    .results-title {
      font-size: 1.3em;
      font-weight: 900;
      color: #aaa;
    }
  }
}

.results-label {
  color: #888;
  display: block;
  margin-top: 10px;
  margin-left: 10px;
  width: 220px;
  line-height: 1.1;

  .results-label-text {
    float: right;
    margin-left: 22px;
    margin-top: -15px;
  }
}

.results-label::before {
  content: "|";
  padding-right: 0px;
}

.results-log-switch {
  display: inline-block;
}
.results-label-log {
  display: inline;
  margin-left: 20px;
  line-height: 12px;
  color: #888;
  font-size: 0.8em;

  .iconLog {
    margin-right: 6px;
  }
}

.full-fallback-label {
  margin-top: 4px !important;
  margin-left: 36px !important;
}

.global-results {
  display: flex;
  align-items: center;
}

.computed-max-button {
  display: block;
  min-width: 86px;
}

.max-label-manual {
  cursor: pointer;
  margin-left: -6px;
  font-size: 0.7em;
  color: rgb(168, 93, 0);
  align-self: flex-end;
  margin-bottom: 2px;

  &:hover {
    color: white;
  }
}

.results-switches {
  //width: 300px;
}
.result-knobs {
  //width: 180px;
  margin-right: 10px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .margin-right {
    margin-right: 10px;
  }
}

.response-fields-selector-select {
  float: left;
  width: 300px;
}

.metadata-in-page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-top: 6px;

  span {
    border: 1px solid darkgreen;
    border-radius: 5px;
    padding: 0px 4px;
    font-size: 0.8em;
    margin-right: 4px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.repeated-meta-header {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.script-filter-box,
.repeated-metadata-box {
  display: inline-block;
  padding: 12px;
  width: 100%;
  border: 1px dotted #444;
  border-radius: 8px;
  margin-bottom: 20px;

  .addBtn {
    display: inline-block;
    margin-left: 5px;
  }

  .selectMeta {
    display: inline-block;
  }

  .title {
    color: #888;
    font-size: 1.1em;
    font-variant: small-caps;
  }

  .response-fields-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin-top: 0px;
    margin-left: -8px;
    vertical-align: middle;
  }
}
.repeated-meta-button {
  // float: left;
  // vertical-align: middle;
  //display: inline-block;
  display: flex;
  flex-direction: column;
  padding: 6px;
  margin-left: 8px;
  //padding-bottom: 4px;
  border: 1px solid #b00;
  border-radius: 8px;
  color: #b00;
  margin-top: 10px;
}

// Vertical Spacing
// .repeated-meta-button:first-child {
//   // margin-top: 6px;
//   margin-bottom: 6px;
// }
// .repeated-meta-button + .repeated-meta-button {
//   // margin-top: 6px;
//   margin-bottom: 6px;
// }
// .repeated-meta-button:last-child {
//   // margin-top: 6px;
//   margin-bottom: 0px;
// }

.repeated-meta-button:hover {
  background: #111 !important;
  border-color: red;
}

.repeated-meta-value-select {
  padding-bottom: 12px;
  padding-top: 12px;
  border-top: 1px dotted #333;

  .popup {
    width: 250px;
    display: inline-block;
  }

  .buttons {
    width: 102px;
    display: inline-block;
  }
}

.repeated-meta-value {
  cursor: pointer;
  color: #888 !important;
}
.repeated-meta-all-values {
  // float: right;
   margin-left: -5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .repeated-meta-perc {
    display: flex;
  }
  .delete {
    cursor: pointer;
    margin-left: 8px;
  }

  .delete:hover {
    color: red;
  }

  .repeated-meta-per,
  .repeated-meta-equals {
    color: #888 !important;
  }
}

.repeated-meta-knob {
  // width: 60px;
  margin-right: 12px;
  margin-left: 0px;
  // float: left;
  // background: #111 !important;
}

.form-check-label{
  margin-left: 5px;
}

.repeated-meta-metabox{
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
}

.inject-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  //float: left;
  min-width: 30px;
  height: 30px;
  padding: 8px;
  border: 1px solid #444;
  color: #666;
  border-radius: 8px;
  background-color: #111;
  margin-right: 6px;
  //margin-bottom: 6px;
  margin-top: 6px;
  text-align: center;
}

.inject-box:hover {
  border-color: #888;
  background-color: #222;
  color: #aaa;
}

.inject-box-position {
  display: block;
  margin: auto;
  //margin-left: -10px;
  margin-top: -3px;
  font-size: 12px;
  //width: 30px;
  text-align: center;
}

.inject-box-open {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  width: 160px;
  height: 120px;

  .inject-box-position {
    align-self: flex-start;
    display: block;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;

    font-size: 18px;
    font-weight: 600;
    min-width: 35px;
    height: 35px;
    text-align: center;

    border: 3px solid #444;
    color: #888;
    border-radius: 40px;
  }
  .inject-box-position:hover {
    border-color: #888;
    color: #aaa;
  }
}

.inject-box-open-extended {
  width: 200px;
  height: 180px;
}

.inject-box-open-block-select {
  width: 300px;
  height: 190px;
  text-align: center;
}

.inject-box.inject-box-extra-results:not(.inject-box-open) {
  //min-width: 10% !important;
  text-align: center;
}

.box-extra-results {
  display: inline-block;
  min-width: 100% !important;
  //overflow-x: hidden;

  .extra-results:not(.extra-results-close) {
    //display: inline-block;
    //width: 100% !important;
    display: block;
    max-width: 280px;
    margin: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.inject-box-filled-block {
  border-color: goldenrod !important;
  color: darkgoldenrod;

  &:hover:not(.inject-box-open) {
    background-color: goldenrod;
    color: #000;
  }
}
.inject-box-filled {
  border-color: #28a745;
  color: #111;

  .inject-box-position {
    border-color: #156d29 !important;
    color: #28a745 !important;
  }

  .inject-box-position:hover {
    border-color: #156d29 !important;
    color: #111 !important;
  }

  .inject-box-position:hover:not(.box-position-close) {
    background: #28a745;
    color: #111 !important;
  }
}

.inject-box-filled:hover {
  border-color: #29cc4f;
  color: #1b8b35;
  background: #1212;
}

.inject-box-filled:hover:not(.inject-box-open):not(.inject-box-open-extended) {
  background: #29cc4f;
}

.inject-box-errored {
  border-color: #dc3545;
  color: #dc3545;

  .inject-box-position {
    border-color: #b90f20;
    color: #dc3545;
  }

  .inject-box-position:hover {
    border-color: #9c1825;
    color: #111 !important;
  }

  .inject-box-position:hover:not(.box-position-close) {
    background: #dc3545;
    color: #111 !important;
  }
}

.inject-box-errored:hover {
  border-color: #9c1825;
  background: #1212;
  color: #111 !important;
}

.inject-box-errored:hover:not(.inject-box-open):not(.inject-box-open-extended) {
  background: #9c1825;
}

.block-compact {
  height: 30px;

  .block-errored {
    float: right;
    margin-top: 2px;
    margin-left: 14px;
    color: #b00;
    margin-right: 25px;
    font-weight: 600;

    -webkit-animation: flash linear 1s infinite;
    animation: flash linear 1s infinite;
  }

  .block-errored-text::before {
    font-weight: 900;
    content: "|";
    margin-right: 6px;
  }

  .block-slots {
    float: right;
    margin-right: 44px;
    margin-top: -6px;
    font-size: 1.1em;
  }

  .block-title {
    float: left;
    font-size: 1.5em;
    font-variant: small-caps;
    margin-top: -10px;
  }

  .block-num-elements {
    font-size: 0.8em;
  }

  .blockPin {
    z-index: 1000;
    width: 40px;
    height: 50px;
    float: left;
    color: yellowgreen;
    font-size: 1.4em;

    margin-top: -8px;
  }

  .pinned {
    color: darkmagenta;
  }

  .defaultPin {
    color: #666;
  }
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.extra-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  //margin-top: 10px;
  margin: 0px !important;
  padding: 0px !important;

  // border: 1px solid #222;
  // border-radius: 8px;
  // padding: 16px;
  // box-shadow: 0 0 10px #121212;
  // background: repeating-linear-gradient(-55deg, #070707, #070707 8px, #000 8px, #000 16px);

  // .extra-box {
  //   //position: absolute;
  //   border: 1px solid #444;
  //   border-radius: 8px;
  //   //padding: 12px;
  //   box-shadow: 0 0 10px #555;
  //   background-color: rgba(0, 0, 0, 0.4);
  //   vertical-align: top;

  //   .extra-title {
  //     font-size: 1.3em;
  //     font-variant: small-caps;
  //     border-bottom: 1px dotted #444;
  //     padding-bottom: 2px;
  //     margin-bottom: 15px;
  //   }

  //   .results-switches {
  //     display: inline-block;
  //     margin-left: 20px;
  //     position: relative;
  //     top: -24px;
  //   }

  //   .result-knobs {
  //     display: inline-block;
  //   }

  //   .no-fallback {
  //     color: #888;
  //   }
  // }
}

.extra-container-window {
  width: 33%;
  margin-right: 10px;
  margin-bottom: 0px !important;

  &:last-child {
    margin-right: 0px;
  }
}

.small-label {
  font-size: 1em;
  font-variant: small-caps;
  color: #888;
  margin-right: 8px;
}

.enable-properties-row {
  padding: 20px;
  padding-top: 5px;
  margin-bottom: 15px;

  border: 1px dotted darkorange;
  border-radius: 12px;

  &:hover {
    border-color: orange;
  }
}

.smart-order-selector-box {
  display: inline-block;
  margin-left: 20px;
}

.property-box {
  display: flex;
  align-items: center;
  margin-left: 10px;

  .property-label {
    font-variant: small-caps;
    color: #888;
    &::after {
      content: ":";
      margin-right: 6px;
    }
  }
}

.smart-order-selector-box {
  display: flex;
  align-items: center;
  justify-content: center;

  .UserTypesSelect {
    margin-left: 10px;
  }
}

.blockKnobs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 280px;

  .max-row {
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    width: 100px;
    font-variant: small-caps;
    color: #888;

    .maxBadge {
      cursor: pointer;
      font-size: 1em;
      background-color: goldenrod;
      color: #000;
      padding: 0px 10px;
      border-radius: 5px;
      margin-left: 4px;
    }
  }
}

.sub-title-edit{
  margin-left: -0px;
  display: flex;
  flex-wrap: nowrap;
}