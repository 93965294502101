.rmsc {
  min-width: 200px !important;
  font-size: 0.9em !important;
  --rmsc-main: #444 !important;
  --rmsc-hover: #888;
  --rmsc-selected: #222;
  --rmsc-border: #555;
  --rmsc-gray: #aaa;
  --rmsc-bg: #222;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 25px; /* Height */
}

.options {
  & > label {
    margin-bottom: 5px !important;
    padding: 5px 10px !important;
    border-bottom: 1px dotted #444 !important;
    color: darkgoldenrod;
  }

  & > li {
    //padding: 0 !important;

    & > label {
      margin-bottom: 0px !important;
      padding: 3px 10px !important;
    }
  }
}

.select-item:hover {
  background-color: #333 !important;
}

.select-item:focus {
  background-color: #444 !important;
}
