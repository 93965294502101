.elements-container {
  display: flex;
  flex-direction: column;

  .element-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start !important;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }

    .item-metadata-missing,
    .item-metadata {
      border: 1px solid green;
      border-radius: 4px;
      padding: 2px 6px;
      font-size: 0.8em;
      min-width: 250px;
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .item-metadata-missing {
      color: red;
      border-color: red;
      text-align: center;
      text-transform: uppercase;
    }

    .list-element {
      //width: 85%;
    }

    .list-drag-icon {
      color: #222;
      font-size: 1.8em;
      margin-top: 1px;
      margin-right: 10px;
      cursor: pointer;
    }

    .delete-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;
      .icon {
        margin-right: 6px;
      }
    }
  }
}

.elements-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 800px;

  .label {
    color: #888;
    font-variant: small-caps;
    width: 100px;
    margin-right: 6px;
    margin-left: 12px;
  }

  .meta-field-select {
    width: 520px;
  }
}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .count-badge {
    font-size: 0.7em;
    background-color: goldenrod;
    padding: 0px 6px 0px 6px;
    border-radius: 4px;
    color: #000;
    margin-left: 6px;
    font-weight: 600;
  }
}

.extra-box {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.footer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .css-box {
    display: flex;
    align-items: center;
    width: 800px;
  }
}

// .list-drag-icon {
//   float: left;
//   color: #222;
//   font-size: 1.8em;
//   margin-top: 1px;
//   margin-right: 10px;
// }

// .list-element {
//   float: left;
//   width: 90%;
// }

// .btnRow {
//   text-align: right;
// }

.csv-input {
  min-width: 400px !important;
}

.csv-col {
  display: flex;
  //justify-content: space-between;
  width: 600px !important;
  align-items: center;
  margin-left: 20px !important;

  width: 500px;
  .data-column-select {
    width: 366px;
  }

  .data-column-btn {
    margin-left: 6px;
  }
}

.csv-input::-webkit-file-upload-button {
  visibility: hidden;
  display: none;
}

.csv-input:focus {
  outline: none;
}
.csv-input::before {
  content: "☯️";
  left: 0;
  background-color: #932ea4;
  border: 1px solid darkviolet;
  color: darkviolet;
  border-radius: 3px;
  padding: 2px 2px 2px 4px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  margin-right: 8px;
}

.csv-input::after {
  content: "Import List from CSV";
  margin-left: 8px;
  font-size: 1em;
  font-variant: small-caps;
  color: #888;
}

.csv-input:hover::before {
  border-color: rgb(96, 2, 136);
  color: darkviolet;
}
.csv-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.pasteBtn {
  margin-left: 6px;
}

.copyBtn {
  margin-left: 6px;
}

// .select-style{
//   width: 100% !important;
// }

.addIcon{
  margin-right: 5px;
}