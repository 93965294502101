.rule {
  width: 100%;
}

.clauses-box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 500px;

  .form-check-inline {
    display: flex;
  }
}
.rule-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .badge {
    font-size: 0.7em;
    font-weight: 700;
    border: 1px solid #222;
    border-radius: 6px;
    padding: 1px 8px 0px 8px;
    margin-right: 10px;
    text-transform: uppercase;
    background-color: #222;
    border-color: #444;
    color: #fff;
  }
  .if {
    @extend .badge;
    // border-color: rgb(134, 1, 1);
    // color: rgb(211, 0, 0);
  }
  .then {
    @extend .badge;
    //background-color: green;
    margin-left: 10px;
  }
}

.skip-switch {
  min-width: 190px;
  margin-left: 30px;
}

.outer-field-container {
  .outer-field-select {
    float: left;
    width: 400px;
  }
  .outer-field-add-button {
    float: left;
    width: 100px;
    margin-left: 10px;
  }

  .outer-field-multi-button {
    float: left;
    margin-left: 10px;
  }
}

.outer-fields-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  //margin-bottom: 10px;
  margin-top: 10px;
}

.and-rule {
  height: 18px;
  text-align: center;
  font-size: 1.2em;
  font-variant: small-caps;
  color: #444;
  margin-top: 5px;
}

.and-rule-label::after {
  content: "And";
}

.and-rule:last-child {
  height: 0px;
  .and-rule-label::after {
    content: "";
  }
  //color: #180c0c;
}

.multiple-fields-badge,
.statements-count-badge {
  font-size: 0.8em;
  background-color: darkolivegreen;
  color: #000;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 0px 4px 0px 4px;
}
.multi-boost {
  //color: #080e14;
  align-self: center;
  font-size: 0.7em;
  padding: 1px 4px 1px 4px;
  border: 1px solid #222;
  border-radius: 4px;
  color: #444;

  &.positive {
    background-color: rgb(1, 70, 1);
    color: #fff;
  }

  &.negative {
    background-color: rgb(122, 3, 3);
    color: #fff;
  }
}

.multi-boost-changer {
  display: flex;

  button {
    margin-left: 6px;
  }
  .negative {
    border-color: rgb(122, 3, 3) !important;
  }
  .positive {
    border-color: rgb(1, 70, 1) !important;
  }
}

.label-main {
  font-size: 0.9em;
  padding: 0px 6px 0px 6px;
  border-radius: 4px;
}

.statement-scheduled-icon {
  color: goldenrod;
  margin-right: 6px;
}
@import "../businessRulesList/clauses";

.main-header {
  display: flex;
  align-items: center;

  .label {
    font-size: 0.8em;
    color: #888;
    text-transform: uppercase;
    margin-right: 4px;
    margin-left: 18px;
    &:first-child {
      margin-left: 0px;
    }
    &::after {
      content: ":";
    }
  }
}

@import "../../scss/noscroll";
@import "../../scss/popover";

.ares-fallback-panel {
  @include popover($color: deeppink, $marginTop: 10px, $left: null);

  .iconShield {
    font-size: 0.9em;
  }
  .popover-body {
    display: block;
    max-height: 400px !important;
    overflow-y: scroll !important;
    @include noScrollbar;
    padding: 0px 15px;

    ul {
      list-style-type: none;
      padding-inline-start: 0px;

      li {
        a {
          padding: 0px;
          padding-right: 6px;
          color: white;
          border-bottom: 1px dotted #666;
        }
        a:hover {
          background: #444;
          color: white;
          text-decoration: none;
        }
      }

      li::before {
        font-size: 0.9em;
        color: deeppink;
        font-weight: 900;
        content: ">" !important;
        margin-right: 6px;
      }
    }
  }
}

.clause-label{
  margin-left:5px !important;
}

.statements-extra{
  display: flex;
  .and-or{
    margin-right: 10px;
  }
}