
.period-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > * {
    margin-right: 10px;
  }
}

.user-similar-knobs {
  display: flex;
}

.rotary-output {
  display: flex;
}
.rowMarginTop {
  margin-top: 10px !important;
}

.rowContent {
  display: flex;
  justify-content: flex-start;
}

.columnLabel {
  max-width: 145px;
}

.compact-meta-btn {
  //background-color: #071920 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-color: #0b242f !important;
  padding-top: 0px !important ;
  padding-bottom: 0px !important ;
  max-height: 22px !important;
}
.content-type-box {
  border: 1px solid #222;
  border-radius: 8px;
  padding: 12px;
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 52px;
  //box-shadow: 0 0 10px #555;

  .content-type-label {
    color: #888;
    font-variant: small-caps;
  }
}

.compact:hover {
  border-color: #444;
  background-color: #111;
}

.content-similar {
  // margin-top: 10px;
  // margin-bottom: 15px;
  // padding: 16px;
  // padding-bottom: 4px;
  // border: 2px solid #444;
  // border-radius: 8px;
}

.metadata-session-container {
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 16px;
  border: 1px solid #888;

  border-radius: 8px;
}

.user-meta-box {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 16px;
  border: 1px solid #444;
  box-shadow: 0 0 10px #555;
  border-radius: 8px;

  .label {
    margin-top: 4px;
    display: inline-block;
    font-size: 1em;
    font-variant: small-caps;
    color: #888;
  }
}

// .singleMeta:last-child::after {
//   content: "";
// }

.formula-operator{
  font-variant: small-caps;
  font-size: 0.9em;
    margin-left: 10px;
    margin-right: 10px;
    color: #888;
}

.metadata-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;
  margin-top: -10px;

  & > * {
    margin-top: 10px;

  }



  .spacer {
    flex-grow: 4;
  }
}

.add-icon {
  margin-right: 4px;
}

.power-off-icon {
  color: #444;
  &:hover {
    color: #666;
  }
}

.colum-icon {
  padding-left: 0px !important;
}
.show-icon {
  cursor: pointer;
  float: right;
  position: relative;
  display: block;
  z-index: 99900099;
}

.error-box {
  display: flex;
  align-items: baseline;
  justify-content: center;
  div {
    margin-right: 20px;
  }
}

.content-similar-aggs {
  padding: 16px;
  border: 2px solid #915e4d;
  border-radius: 12px;
  box-shadow: 0 0 10px salmon;
}

.fingerprint-box {
  padding: 16px;
  border: 2px solid darkgray;
  border-radius: 12px;
  box-shadow: 0 0 10px darkgray;
  margin-bottom: 20px;
}

.fingerprint-fields,
.fingerprint-unlike {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dotted #444;


  .title {
    font-size: 1.2em;
    font-variant: small-caps;
    font-weight: 400;
    color: #888;
    margin-bottom: 10px;
  }

  .selectContainer {
    //background-color: darkgoldenrod;
    .selectMeta {
      width: 466px;
      float: left;
    }

    .addBtn {
      display: inline-block;
      margin-left: 14px;
    }
  }

  // .fields,
  // .unlikes {
  //   background-color: red;
  //   display: flex;
  //   flex-wrap: wrap;
  //   align-items: center;
  //   justify-content: space-between;
  //   justify-items: space-between;
  //   width: 100%;
  //   margin-top: 60px;
  // }
}

.fields,
.unlikes {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  //justify-content: space-between;
  align-content: space-between;
  width: 100%;

  & > * {
    margin-top: 10px;
    margin-right: 6px !important;
  }

  .spacer {
    flex-grow: 2;
  }
}

.fingerprint-kobs {
  display: flex;
  margin-top: 20px;
  & > * {
    margin-right: 10px;
  }
}

.fingerprint-fields {
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}
.content-similar-date-range {
  margin-top: 20px;
}
.content-similar-exclude {
  padding: 16px;
  border: 2px solid red;
  box-shadow: 0 0 10px red;
  border-radius: 12px;
  margin-bottom: 20px;
}

.meta-from {
  margin-left: 3px;
}

.meta-from::before {
  content: "=";
  color: #aaa;
  margin-right: 3px;
}

.close-x {
  cursor: pointer;
}

.close-x:hover {
  color: #a00;
}

.from-extra {
  //margin-top: 20px;
  margin-bottom: 10px;
  max-width: 800px;
  border: 1px solid #222;
  border-radius: 8px;
  padding: 16px;
  padding-top: 4px;

  .meta-value-label {
    color: #888;
    font-size: 1.1em;
    font-variant: small-caps;
  }

  .meta-value-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    button {
      margin-left: 14px;
      &:last-child {
        margin-left: 6px;
      }
    }
  }
}

.confirmExtra {
  margin-top: 10px;
  float: right;
}

.keepOnlyBox {
  padding: 16px;
  padding-bottom: 0px;
  border: 2px solid #5a0000;
  box-shadow: 0 0 10px green;
  border-radius: 8px;
  margin-bottom: 20px;
}

.singleValue::after {
  content: " OR ";
  font-size: 0.8em;
  margin-left: 5px;
  margin-right: 5px;
  color: #888;
}
.singleValue:last-child::after {
  content: "";
}

.originBox {
  margin-bottom: 10px;
  padding: 16px;
  padding-bottom: 0px;
  padding-top: 5px;
}

.knob-group-period {
  margin-top: -8px;
  //margin-left: -85px;
}
.knob-results {
  margin-top: -8px;
  margin-left: -85px;
}

.knob-group-generic {
  margin-left: 0px;
  margin-right: 18px;
}

.knob-group-results {
  margin-top: 8px;
  margin-left: 25px;
}

.advanced {
  margin-left: 3px;
  vertical-align: middle;
  cursor: pointer;
}
.advanced:hover {
  color: deepskyblue;
}

.advOpen {
  color: dodgerblue;
}

.advClose {
  color: #888;
}

.advClose::before {
  font-variant: small-caps;
  font-size: 0.9em;
  margin-right: 1px;
  vertical-align: text-bottom;

  content: "expert";
}

.group-by-select-row {
  padding-bottom: 8px;
}
.group-by-select-row:last-child {
  padding-bottom: 0px;
}

.group-by-select {
  width: 466px;
  display: inline-block;
  margin-right: 14px;
}

// .group-by-box,
// .order-by-box {
//   padding: 16px;
//   border: 1px dotted #444;
//   border-radius: 8px;
//   box-shadow: 0 0 6px #222 inset;
// }
// .order-by-box {
//   margin-bottom: 20px;
// }
.order-by-select-row {
  padding-bottom: 8px;
}
.order-by-select-row:last-child {
  padding-bottom: 0px;
}
.order-by-select {
  width: 466px;
  display: inline-block;
  margin-right: 14px;
}
.order-by-switch {
  display: inline-block;
  margin-right: 14px;
}

.divider {
  border-top: 1px dotted #444;
}

.field-output {
  display: inline-block;
  color: #888;
  margin-top: 5px;
  font-size: 1.2em;
  font-variant: small-caps;
  font-weight: 900;
}

.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 100;
  color: #333;
  background: #222;
}

.bootstrap-switch.bootstrap-switch-focused {
  border-color: #444;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(6, 18, 27, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(13, 28, 41, 0.6);
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  background: blueviolet;
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: darkmagenta;
}

.score-label {
  display: inline;
  background-color: darkgoldenrod;
}

// .collaborative-extra {
//   margin-left: -10px;
//   display: flex;
//   align-items: baseline;

//   & > *{
//     //width: unset !important;
//     margin-right: 10px;
//   }

//   .fingerprintTypeSelect {
//     margin-left: 20px;
//     .label {
//       font-variant: small-caps;
//       color: #888;
//     }
//   }
// }

.request {

  .request-select {
    display: flex;
    align-items: center;
    justify-items: space-between;
    margin-right: 8px;

    .request-add-btn {
      margin-left: 6px;
    }
  }
}

.request-list {
  display: flex;
  //justify-content: space-between;
  margin-top: 10px !important;
  padding: 0px 14px 0px 14px;
  .request-list-delete-icon {
    margin-left: 6px;
  }
}

.user-content-type-edit {
  cursor: pointer;
  color: #888;
  font-size: 1em;
  vertical-align: middle;

  .icon {
    font-size: 0.8em;
    vertical-align: baseline;
    margin-left: 6px;
  }
}
.collaborative-output-edit {
  margin-left: -10px;
  cursor: pointer;
  color: #888;
  font-size: 1em;

  .input {
    display: inline-block;
  }

  .btn {
    display: inline-block;
    margin-left: 6px;
  }
  .field {
    display: inline-block;
    margin-right: 6px;
    color: #aaa;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .icon {
    font-size: 0.8em;
    vertical-align: middle;
  }
  .icon:hover {
    color: #fff;
  }
}

.label-type {
  font-size: 1.3em;
  color: #888;
  font-variant: small-caps;
}

.label-contentType {
  font-size: 1.1em;
  color: #888;
  font-variant: small-caps;
  margin-right: 8px;
}

.extra-box {
  display: flex;
  align-items: center;
  color: deeppink;
  font-size: 0.8em;
  text-transform: uppercase;

  .warning-icon {
    color: deeppink;
    margin-right: 6px;
  }
}



@import "../../scss/noscroll";
@import "../../scss/popover";

.ares-fallback-panel {
  @include popover($color: deeppink, $marginTop: 10px, $left: null);

  .iconShield {
    font-size: 0.9em;
  }

  .popover-body {
    display: block;
    max-height: 400px !important;
    overflow-y: scroll !important;
    @include noScrollbar;
    padding: 0px 15px;

    ul {
      list-style-type: none;
      padding-inline-start: 0px;

      li {
        a {
          padding: 0px;
          padding-right: 6px;
          color: white;
          border-bottom: 1px dotted #666;
        }

        a:hover {
          background: #444;
          color: white;
          text-decoration: none;
        }
      }

      li::before {
        font-size: 0.9em;
        color: deeppink;
        font-weight: 900;
        content: ">" !important;
        margin-right: 6px;
      }
    }
  }
}