.contentPackBox {
  min-width: 980px;
  color: #fff;

  .row {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }

  .column {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
  }

  .blockLabel {
    font-variant: small-caps;
    color: #888;
  }

  .metadataBox {
    padding-left: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    //margin-top: -10px;

    & > * {
      margin-top: 10px;
    }

    .icon-no-origin {
      color: goldenrod;
      margin-left: 6px;
    }

    .spacer {
      flex-grow: 4;
    }
  }

  .useSimilarity {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 500;
    padding: 2px 6px;
    border-radius: 5px;
    background-color: darkmagenta;
  }

  .maxDocs {
    width: 200px !important;
  }

  .fingerMeta {
    & > * {
      margin-right: 5px;
    }
  }

  .periodInput {
    width: 350px !important;
  }

  .noOriginIcon {
    color: goldenrod !important;
    margin-left: 6px;
  }

  .orderByBox {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px dotted #444;
  }

  .resultsPerGroup {
    display: flex;
    justify-items: baseline;
    align-items: flex-end;
  }
}

.settingsHeader {
  display: flex;
  min-width: 800px;

  .label {
    color: #888;
    font-variant: small-caps;
    &::after {
      content: ":";
      margin-right: 5px;
    }

    &:first-child {
      margin-left: 0px;
    }
    margin-left: 15px;
  }
  .outputField {
    width: 366px;
  }

  .select {
    width: 200px !important;
  }
}

.outputHeader {
  display: flex;
  min-width: 900px;

  .label {
    color: #888;
    font-variant: small-caps;
    margin-right: 10px;
  }

  .scoreMax {
    margin-left: 15px;
    margin-right: -180px;
  }

  .results {
    margin-left: 15px;
  }
}

.previewHeader {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  height: 30px !important;

  & > div {
    margin-right: 20px;
  }

  .previewUserId {
    width: 366px;
    margin-left: 10px;
  }
}

.previewReload {
  color: #888;
}

.previewLoading {
  color: greenyellow;
}

.idField {
  width: 80px !important;
}

.userHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .label {
    display: block;
    width: 140px;
    color: #888;
    font-variant: small-caps;
  }
}
