.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 100;
  color: #333;
  background: #222;
}

.bootstrap-switch.bootstrap-switch-focused {
  border-color: #444;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(6, 18, 27, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(13, 28, 41, 0.6);
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  background: blueviolet;
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: darkmagenta;
}

.rmsc {
  min-width: 364px !important;
  font-size: 0.9em !important;
  --rmsc-main: #444 !important;
  --rmsc-hover: #888 !important;
  --rmsc-selected: #222 !important;
  --rmsc-border: #444 !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #222 !important;
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 4px !important; /* Radius */
  --rmsc-h: 25px !important; /* Height */
}

.options {
  & > label {
    margin-bottom: 5px !important;
    padding: 5px 10px !important;
    border-bottom: 1px dotted #444 !important;
    color: darkgoldenrod;
  }

  & > li {
    //padding: 0 !important;

    & > label {
      margin-bottom: 0px !important;
      padding: 3px 10px !important;
    }
  }
}

.select-item:hover {
  background-color: #333 !important;
}

.select-item:focus {
  background-color: #444 !important;
}
