.image {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #222;
  background-color: #111;

  .icon {
    font-size: 4em;
    color: #222;
    &:hover {
      color: #333;
    }
  }
}
