.ruleSelect {
  display: flex;
  margin-bottom: 10px;
  .label {
    margin-right: 8px;
    font-variant: small-caps;
    color: #888;
  }
}

.contentTypeLabel {
  font-size: 0.9em;
  border: 1px solid #888;
  border-radius: 4px;
  padding: 1px 6px 1px 6px;
  font-variant: small-caps;
}
