.powerOffIcon {
  color: #888;
}

.rangeHeader {
  display: flex;



  .period {
    width: 100px !important;
    margin-right: 10px;
  }
  .unit {
    width: 200px;
  }
}

.start {
  margin-left: 10px;
  display: flex;

  .resetStartDate {
    color: #888;
    margin-left: 5px;

    &:hover {
      color: #eee;
    }
  }

  .startDate {
    font-variant: small-caps;
    color: #eee;
  }
}

.label {
  font-variant: small-caps;
  color: #888;
  margin-left: 10px;

  &:first-child {
    margin-left: 0px;
  }

  &::after {
    content: ":";
    margin-right: 5px;
  }
}
