 @import "./noscroll";


 .table {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
          "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    thead{
      & > tr > * {
        background-color: #1f1e1e  !important;
      }
    }

        & >:not(:first-child) {
          border-top: 0px !important;
        }

    tbody{
      //border: 0px !important;
      @include noScrollbar;
    }

         a {
           text-decoration: none !important;
           color: #fff !important;
    
           &:hover {
             border-bottom: 2px #444 dashed;
           }
         }
 }

 .table-dark {
   --bs-table-bg: #000;
   --bs-table-striped-bg: #111;
   --bs-table-striped-color: #fff;
   --bs-table-active-bg: #333;
   --bs-table-active-color: #fff;
   --bs-table-hover-bg: #222 ;
   --bs-table-hover-color: #fff;
   color: #fff;
   border-color: #000;
 }

