.extraBox {
  //color:#1d0906;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .extra {
    align-self: baseline;
    margin-right: 20px;
  }

  .locked {
    font-size: 0.8em;
    color: rgb(124, 92, 9); //#888;
    text-transform: uppercase;
    margin-left: 10px;
    margin-right: -15px;
  }

  .toolbar {
    align-self: flex-end;
    width: 50px;
    //height: 30px;
    text-align: right;

    .extraMenu {
      a {
        color: #666;

        &:hover {
          color: #aaa;
        }
      }
    }

    .copyIcon {
      margin-right: 8px;
      color: deepskyblue;
    }

    .pasteIcon {
      margin-right: 8px;
      color: green;
    }

    .resetIcon {
      margin-right: 8px;
      color: darkorchid;
    }

    .disabledMenuItem {
      color: #444 !important;

      .resetIcon,
      .pasteIcon,
      .copyIcon {
        opacity: 0.4;
      }
    }
  }
}

.lockIcon {
  margin-right: 6px;
  color: darkgoldenrod !important;
}

.lockIconSave {
  margin-right: 6px;
  color: yellow !important;
}

@import "../../scss/noscroll";



.diffIcon{
  margin-left: 10px;
  margin-right: -10px;
  color: #333;
  font-size: 0.8em;

  &:hover{
    color: darkmagenta;
  }
}

.itemTree {
  color: #333 !important;

  &:hover{
    color: darkviolet  !important;
  }
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.itemWarning{
  color: gold !important;
  font-size: 1.2em;

  -webkit-animation: flash linear 1.5s infinite;
    animation: flash linear 1.5s infinite;

  &:hover{
    color: yellow !important;
  }
}

.editorBox{
  .warning{
    padding: 10px;
    padding-bottom: 0px;
    background-color: rgb(62, 0, 88);
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    //min-height: 115px;

    
   .label{
     width: 100%;
     font-size: 1.2em;
     padding-bottom: 5px;
     margin-bottom: 5px;
     border-bottom: 1px solid darkmagenta;
     font-weight: 900;
   }

   .explanation{
     color:#aaa;
     font-size: 1em;

   

     .warn{
      display: list-item;
        list-style-type: disc;
        list-style-position: inside;
       margin-bottom: 2px;
       &:last-child{
        margin-bottom: 5px;
       }
      }
    
   }

   .fixBox{
     display: flex;
     flex-direction: column;
     width: 100%;
    

     .fixBtn{
       align-self: flex-end;
        margin-bottom: 10px;
     }
   }

    & >*{
      margin-right: 10px;
    }
  }

  .pageTab{
    .icon{
      color: darkmagenta;
      margin-right: 5px;
    }
  }
}

//  .metaBox {
//    display: flex;
//    margin-bottom: 10px;

//    .metadata {
//      cursor: pointer;
//      border: 1px solid darkorchid;
//      border-radius: 5px;
//      padding: 0px 6px 0px 6px;
//      margin-right: 5px;
//    }
//  }

.groupRefBox{
  padding: 5px;
  .groupRefPane{
    margin-bottom: 2px !important;

    .scrollBox{
      @include noScrollbar;
      
      max-height: 300px;
      overflow-y: scroll;

      .groupRefSubPane{
        margin-bottom: 2px !important;

        .subBox{
          min-height: 200px !important;
        }
      }
    }
  }
}

.refLength{
  margin-left: 10px;
  font-size: 0.8em;
  background-color: darkcyan;
  color: #000;
  padding: 0px 6px 0px 6px;
  border-radius: 5px;
}

.linkIcon{
  
  margin-left: 10px;
  border-bottom: 0px !important;

  .icon{
    font-size: 0.7em;
    color: #333;

    &:hover {
      color: #555;
    }
  }
  &:hover{
    border-bottom: 0px !important;
  }
}



.synthPane{
  padding: 5px;
  .container{
    margin-bottom: 2px !important;

    .metaElement{
      display: flex;
      flex-wrap: nowrap;
      margin-right: 10px;
      align-items: baseline;

      .key{
        &::after{
          content:":";
        }
      }
      .value{
        align-self: flex-end;
        .subValue {
            border: 1px solid #444;
            border-radius: 5px;
            padding: 0px 6px 0px 6px;
            color: #aaa;
          }
      }
     

    }
    .infoBox{
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      font-size: 0.8em;
    }
    .infoMetaBox{
      @include noScrollbar;
      width: 620px;
      overflow-x: scroll;
      display: flex;

      .metadataHot,
      .metadata{
        font-size: 0.8em;
        border: 1px solid #444;
        border-radius: 5px;
        padding: 0px 6px 0px 6px;
        margin-right: 5px;
      }

      .metadataHot{
        border-color:  darkorange !important ;
      }
    }

    .list{
      display: flex;
      flex-direction: column;
      .row{
        display: flex;
        font-size: 0.8em;
       
      }
    }

         .badgeIcon {
           font-size: 0.7em;
           background-color: rgb(19, 87, 156);
           min-width: 13px;
           border-radius: 4px;
           padding: 0px 4px 0px 4px;
         }
    
         .okIcon {
           color: green;
         }
    
         .koIcon {
           color: #444;
         }
  }
}

.refBadge{
  cursor: pointer;
  margin: auto;
  margin-bottom: 5px;
  font-size: 0.8em;
  width: 55px;
  padding: 0px 5px 0px 5px;
  border: 1px dotted darkmagenta;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  &:hover{
    background-color: darkmagenta;
    color: #fff;
  }

  .pipe{
    color: #888;
  }

  .warn{
  color: rgb(68, 37, 0) !important;
  }
}

.refBadgeWarning{
  color: #000;
  border: 1px dotted darkgoldenrod;
  background-color: orange;


  &:hover{
    background-color: rgb(134, 98, 7);
  }
}