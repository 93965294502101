@mixin iconEffect {
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
}

@import "../../scss/noscroll";

// .container {
//   padding: 12px;
//   padding-bottom: 0px;
//   margin-bottom: -12px;

//   // border: 1px solid #222;
//   // border-radius: 8px;

//   .header {
//     cursor: pointer;
//     height: 0px;

//     .title {
//       font-size: 1.3em;
//       color: #aaa;
//     }
//     .toggleIcon {
//       margin-top: 4px;
//       float: right;
//       font-size: 1.4em;
//       color: #444;
//     }
//   }

//   .extraDropIcon {
//     display: inline-block;
//     margin-left: 0px;
//     a {
//       color: #fff;

//       &:hover {
//         color: #aaa !important;
//       }
//     }

//     .copyIcon {
//       margin-right: 8px;
//       color: deepskyblue;
//     }

//     .pasteIcon {
//       margin-right: 8px;
//       color: green;
//     }

//     .disabledMenuItem {
//       color: #444 !important;

//       .pasteIcon,
//       .copyIcon {
//         opacity: 0.4;
//       }
//     }
//   }

//   .openBorder {
//     border-top: 1px dotted #444;
//     margin-top: 35px;
//   }

//   .openView {
//     // padding: 12px;
//     // padding-top: 0px;
//     // border: 1px solid #222;
//     // border-radius: 8px;

//     .draggableTabs {
//       margin-top: 20px;
//     }
//     .paramsTable {
//       margin-top: 10px;
//       .columnActive {
//         width: 20px;
//         text-align: center;
//       }
//       .columnParam {
//         width: 250px;
//         padding-left: 10px;
//       }
//       .rowInactive {
//         color: #666;

//         input[type="checkbox"] {
//           background-color: red !important;
//         }
//       }
//     }

//     .paramsTab {
//     }
//     .editTab {
//       display: inline-block;

//       .field {
//         display: inline-block;
//         width: 80%;
//       }

//       .btn {
//         display: inline-block;
//         margin-left: 6px;
//       }
//     }
//   }

//   .closeView {
//     padding-bottom: 15px;
//     .scroller {
//       @include noScrollbar;

//       max-width: 97%;
//       overflow-x: scroll;

//       .sets {
//         display: flex;
//         flex-wrap: nowrap;
//         width: 100%;

//         .set {
//           background-color: #000 !important;
//           margin-right: 0px;
//           max-height: 30px;
//           min-width: 100px;
//           white-space: nowrap;
//           overflow: hidden;
//           text-overflow: ellipsis;
//         }
//         .activeSet {
//           background-color: #222;
//           color: deeppink;
//           font-weight: 900;
//         }
//       }
//     }
//   }
// }

.draggableTabs {
  //margin-top: 20px;
}
.paramsTable {
  margin-top: 10px;
  .columnActive {
    width: 20px;
    text-align: center;
  }
  .columnParam {
    width: 250px;
    padding-left: 10px;
  }
  .rowInactive {
    color: #666;

    input[type="checkbox"] {
      background-color: #888 !important;
    }
  }
}

.paramsTab {
  //height: 10px;
  //background-color: palevioletred;
}
.editTab {
  width: 300px;
  display: inline-block;

  .field {
    display: inline-block;
    width: 80%;
  }

  .btn {
    display: inline-block;
    margin-left: 6px;
  }
}

.extraDropIcon {
  display: inline-block;
  margin-left: 10px;
  a {
    color: #fff;

    &:hover {
      color: #aaa !important;
    }
  }
}

.copyIcon {
  margin-right: 8px;
  color: deepskyblue;
}

.pasteIcon {
  margin-right: 8px;
  color: green;
}

.disabledMenuItem {
  color: #444 !important;

  .pasteIcon,
  .copyIcon {
    opacity: 0.4;
  }
}

.scroller {
  display: flex;
  flex-wrap: nowrap;
  @include noScrollbar;
  max-width: 100%;
  overflow-x: auto;

  .set {
    flex: none;
    border-color: #30061d !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  .activeSet {
    background-color: #222;
    color: deeppink;
    font-weight: 900;
  }
}

.title {
  margin-right: 4px;
}
.icons {
  .copyIcon {
    @include iconEffect;
    margin-left: 4px;
    color: darkgoldenrod;
  }
  .copyToClipboardIcon {
    @include iconEffect;
    margin-left: 4px;
    color: deepskyblue;
  }

  .deleteIcon {
    @include iconEffect;
    margin-left: 4px;
    color: red;
  }
}

.paramsTooltip {
  background-color: #000;
  width: 550px;
  text-align: left !important;
  padding: 12px;
  overflow-y: scroll;
  @include noScrollbar;

  .row {
    border-bottom: 1px dotted #444;
    vertical-align: middle;
    margin-bottom: 4px;

    &:last-child {
      border-bottom: 0px;
      margin-bottom: 0px;
    }
    .key {
      text-align: right;

      display: inline-block;
      width: 150px;
      color: deepskyblue;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .value {
      padding-left: 6px;
      display: inline-block;
      width: 370 px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
