@import "../../scss/popover";

.arrowAfter {
    top: 20px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-right-color: transparent !important;
    margin-top: 0px !important;
}

.ares-user-pref-panel {
    @include popover($color: darkgoldenrod, $marginTop: 2px, $left: 5px);
}