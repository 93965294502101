@mixin noScrollbar {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
    background-color: #111;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #111;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
