.container {
  display: flex;
  flex-direction: column;

  .row{
    display: flex;
    align-items: stretch;
    width: 100%;
    margin-bottom: 10px;

    &:last-child{
      margin-bottom: 0px;
    }

    .column {
        width: 50%;
        margin-right: 10px;
    
        &:last-child {
          margin-right: 0px;
        }
      }
  }
  

  .restIcon {
    color: #888;
    &:hover {
      color: #aaa;
    }
  }

  .keySelectBox {
    display: flex;

    .selectKey {
      width: 366px;
    }

    .keySelectBtn {
      margin-left: 10px;
      .keySelectIcon {
        margin-right: 5px;
      }
    }
  }
}

.rulesBox {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 10px;
  margin-bottom: -10px;

  .ruleTitle {
    color: #888 !important;
    margin-left: 5px;
  }

  .deleteRuleIcon {
    font-size: 0.8em;
    margin-left: 0px;
    color: #444;
    //margin-left: 10px;

    &:hover {
      color: red;
    }
  }

  .valueError {
    font-size: 0.9em;
    color: black;
    border-radius: 5px;
    padding: 0px 6px;
    background-color: red;
    opacity: 0.7;
  }

  .operatorHead,
  .operator {
    color: #666;
    font-size: 0.8em;
    margin-left: 5px;
    margin-right: 5px;
  }

  .operator {
    margin-right: 10px;
  }

  .single {
  }

  .ruleHead {
    display: flex;

    .ruleValuesSelect {
      width: 366px;
    }
    .valueSelectBtn {
      margin-left: 10px;
      margin-right: 10px;

      .keySelectIcon {
        margin-right: 5px;
      }
    }
  }

  .ruleValuesBox {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .singleValue {
      display: flex;
      align-items: center;

      cursor: pointer;
      font-size: 0.9em;
      border: 1px solid #444;
      border-radius: 5px;
      padding: 0px 6px;

      &:hover {
        border-color: #aaa;
        color: deepskyblue;
      }

      .closeX {
        margin-left: 5px;
        &:hover {
          color: red;
        }
      }
    }
  }
}

.valueBox,
.positionBox{
  display: flex;
  flex-direction: column;

  .row{
    display: flex;
    justify-content: space-between;
    align-content: flex-start;

    .label{
      display: inline-block;
      width: 140px !important;
    }

    .operatorsSelect{
      max-width: 100%;

      &:active,
      &:focus{
        box-shadow: none !important;
        outline: 0px !important;
      }
    }

    .valuesBox{
      display: flex;
        flex-wrap: nowrap;
        width: 210px;
     
      .positionInput {
       
        margin-left: 10px;
         //max-width: 100px;

          &:first-child {
              margin-bottom: 5px;
          }
        }
    }
  }
}