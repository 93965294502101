.item-container {
  position: relative;
  z-index: 0;
  background-color: #000 !important;
  box-shadow: 0 0 30px #212121 !important;
  border: 2px solid #222;
  border-radius: 8px;
  width: 100%;

  .item-body {
    padding: 18px;
  }

  .item-footer {
    display: flex;
    flex-direction: row-reverse;
    border-top: 2px solid #222;
    padding: 8px 18px 8px 18px;
    box-shadow: inset 0 0 5px #212121 !important;
    //background-color: #111;
  }
}

.item-header {
  font-weight: 900;
  font-size: 1.5em;
  border-bottom: 10px;
  color: #aaa;
}
.lock-item {
  color: #444;
  margin-left: 2px;
  font-size: 0.7em;
}

.schedule-block {
  color: goldenrod;
  margin-left: 6px;
  span {
    font-size: 1.2em;
  }
}
.schedule-item {
  margin-left: 16px;
  margin-right: 4px;
  font-size: 0.7em;
}

.schedule-item-label {
  margin-left: 7px;
  font-size: 0.7em;
  font-variant-caps: all-small-caps;
}

.title-box {
  .go-back {
    margin-right: 6px;
    cursor: pointer;
    color: #444;

    &:hover {
      color: #666;
    }
  }

  .title {
    text-transform: capitalize;
    font-weight: 900;
    color: #bbb;
    font-size: 1.2em;
  }
}

.header {
  display: flex;
  align-items: center;
  .id-label {
    color: #999;
    text-transform: uppercase;
    font-variant: small-caps;
  }
  .id {
    color: #666;
    &:hover {
      color: #fff;
    }
  }
  .copyId {
    font-size: 0.9em;
    color: #222;
    margin-left: 4px;

    &:hover {
      color: #444;
    }
  }
}


.ares-scheduling-item-pop > .arrow::after,
.ares-scheduling-item-pop > .arrow::before {
  top: 20px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: goldenrod !important;
  border-right-color: transparent !important;
  margin-top: 7px !important;
}

.ares-refbox-pop >.arrow::after,
.ares-refbox-pop>.arrow::before
{
  top: 20px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: darkviolet !important;
  border-right-color: transparent !important;
  margin-top: 7px !important;
}

@import "../../scss/popover";
.ares-scheduling-item-pop {
  @include popover($color: goldenrod, $left: null, $width: 99vw, $marginTop: 10px);
}
.ares-refbox-pop {
  @include popover($color: darkviolet, $left: null, $width: 45vw, $marginTop: 2px);
}
