.pills{
    padding: 0px 0px !important;
    border-radius: 8px;
    //border: 2px solid rgb(45, 14, 45);
    margin-bottom: 10px;

    &:hover{
        border-color: #222;
    }
}

.container{
    margin-top: -10px;
}