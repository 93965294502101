.container {
  min-width: 500px;
  min-height: 100px;
  background-color: #111 !important;
  border-radius: 8px;
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid deepskyblue;
    padding-bottom: 0px;
    .pane {
      padding: 2px 10px;
      .title {
        font-size: 1.2em;
        font-weight: 500;
        color: deepskyblue;

        .icon {
          font-size: 0.9em;
          margin-right: 10px;
          color: darken(#00bfff, 10%);
        }
      }
    }
  }

  .body {
  }
}

.activeTask {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  .title {
    color: #fff;
    margin-top: 6px;
    text-transform: uppercase;
  }

  .typeBox {
    display: flex;
    align-items: center;
    .type {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid deepskyblue;
      padding: 0px 6px;
      border-radius: 5px;
      margin-right: 6px;
    }

    .id {
      color: #888;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid deepskyblue;
      border-color: #888;
      padding: 0px 6px;
      border-radius: 5px;
      &::before {
        content: "Id: ";
      }
    }
  }
}

.table {
  margin-bottom: 0px !important;
  padding: 0px !important;

  .padding {
    padding: 4px 6px 2px 10px !important;
  }
}

.lockIcon {
  margin-right: 6px;
  color: goldenrod;
}
