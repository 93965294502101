.header {
  display: flex;
  align-items: center;

  .tie {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .input {
    width: 80px;
    //height: 22px !important;
  }

  .check {
    margin-left: 6px;
  }

  .label {
    color: #888;
    font-variant: small-caps;
    &::after {
      content: ":";
      margin-right: 6px;
    }
  }
  .value {
    font-size: 0.8em;
    font-weight: 600;
    color: #fff;
    background-color: #333;
    border-radius: 5px;
    padding: 0px 6px;
  }
}
