$itemColor: darkgoldenrod;

.container{
    display: flex;
    flex-direction: column;

    .box{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
        align-items: flex-start;

        .item{
            cursor: pointer;
            z-index: 999;
            // display: flex;
            // flex-wrap: nowrap;
            padding: 0px 6px 0px 6px;
            color: $itemColor;
            border: 1px solid $itemColor;
            border-radius: 4px;
            font-size: 0.9em;
            margin-right: 5px;

            &:hover{
                color: lighten($itemColor, 20%);
                border-color: lighten($itemColor, 20%);
            }
            &:last-child{
                margin-right: 0pxs;
            }

            .delete{
                display: flex;
                justify-content: flex-end;
                margin-left: 5px;
                margin-right: -5px;
                color: #444;
            
                &:over{
                    color:red;
                }
            }
        }
    }
}

.itemTitle{
    color: $itemColor;
}

.editoreBox{
    z-index: 999;
}