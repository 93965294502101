@import "../../scss/noscroll";

.header {
  display: flex;
  .radio {
    margin-right: 10px;
  }
}

.extra {
  display: flex;
  & > * {
    color: #888;
    font-size: 0.9em;
    margin-left: 5px;
    font-variant: small-caps;
    padding: 0px 6px 0px 6px;
    border: 1px solid #444;
    border-radius: 5px;
  }
}

.cacheHeader {
  display: flex;
  & > * {
    width: 150px;
    margin-right: 5px;
  }
}

.previewHeader {
  display: flex;
  height: 30px !important;

  .previewUserId {
    width: 366px;
    margin-left: 10px;
  }
}

.synthValueBox {
  display: flex;
  flex-wrap: wrap;

  .synthValue {
    cursor: pointer;
    margin-right: 5px;
    padding: 0px 4px;
    border: 1px dotted #444;
    border-radius: 5px;

    &:hover {
      background-color: rgb(2, 79, 79);
    }
  }
}

.scrollContainer {
  @include noScrollbar;
  height: 300px !important;
  overflow-y: scroll !important;
}

.tagSegHeader {
  display: flex;
  flex-wrap: wrap;
  .tagSeg {
    cursor: pointer;
    margin-right: 5px;
    padding: 0px 4px;
    border: 1px dotted darkslateblue;
    border-radius: 5px;

    &:hover {
      background-color: rgb(2, 79, 79);
    }
  }
}

.contentCount {
  font-size: 0.9em;
  padding: 0px 4px;
  border-radius: 5px;
  background-color: darkmagenta;
}

.selects {
  display: flex;

  .paramsLabel {
    color: #888;
    font-variant: small-caps;
    margin-right: 5px;
    margin-left: 15px;

    &::after {
      content: ":";
    }
  }
}

.periodSelect {
  width: 200px;
}

.numElements {
  margin-left: 10px;
}

.unitSelect {
  width: 100px !important;
}
