$white: #fff !default;
$black: #000 !default;
$popover-bg: $white !default;
$border-width: 1px !default;
$border-radius-lg: 0.3rem !default;
$popover-border-width: $border-width !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $popover-bg !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, 0.2) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width) !default;

@mixin popover(
  $color,
  $rotation: null,
  $top: -7px,
  $left: 4px,
  $right: null,
  $marginTop: null,
  $width: null,
  $minWidth: null,
  $height: null,
  $minHeight: null,
  $border: 1px,
  $arrowSize: 6px
) {
  position: absolute;
  background-color: #222 !important;
  border: $border solid $color !important;
  border-radius: 4px;
  color: #fff;
  height: auto;
  opacity: 1 !important;
  overflow-y: visible !important;
  z-index: 9999 !important;

  @if $marginTop {
    margin-top: $marginTop !important;
  }

  @if $height {
      height: $height  !important;
  }
  @if $minHeight {
    min-height: $minHeight !important;
  }
  @if $width {
    width: $width !important;
  }
  @if $minWidth {
    min-width: $minWidth  !important;
  }

  .popover-header {
    width: 100%;
    background-color: #333;
    border-bottom: 1px solid #000;
    color: $color;

    &::before {
      display: none;
    }
  }

  .popover-body {
    background-color: #111 !important;
    color: #fff;
    display: block;
    height: auto;
    max-height: 90% !important;
    overflow-y: visible !important;
    border-radius: 4px;
    opacity: 1 !important;

    li::before {
      content: "" !important;
    }
  }

  // & > .arrow {
  //   top: $top !important;

  //   color: $color;

  //   @if $left {
  //     left: $left !important;
  //   }

  //   @if $rotation {
  //     transform: rotate($rotation) !important;
  //   }
  //   width: 0;
  //   height: 0;
  //   border-left: $arrowSize solid transparent;
  //   border-right: $arrowSize solid transparent;

  //   border-bottom: $arrowSize solid $color;
  // }

     a {
       text-decoration: none !important;
       color: #fff !important;
  
       &:hover {
         border-bottom: 2px #444 dashed;
       }
     }
     
  & > .popover-arrow {
    top: $top !important;

    color: $color;

    @if $left {
      left: $left !important;
    }

    @if $rotation {
      transform: rotate($rotation) !important;
    }
    width: 0;
    height: 0;
    border-left: $arrowSize solid transparent;
    border-right: $arrowSize solid transparent;

    border-bottom: $arrowSize solid $color;
  }
}
