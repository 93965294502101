.inputBox,
.injectHeader {
  display: flex;
  flex-wrap: nowrap;

  & > * {
    margin-right: 10px;
  }

  .label {
    color: #444;
    font-variant: small-caps;
  }

  .input {
    width: 366px;
  }

  .valore {
    color: dodgerblue;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .idField {
    width: 150px !important;
  }
}

.deleteIcon {
  color: rgb(88, 1, 1);
  font-size: 0.9em;
  &:hover {
    color: red;
  }
}

.pipeIcon {
  color: darkslategrey;
  font-size: 0.9em;
}

.apiHeader {
  display: flex;

  .unitSelect {
    width: 100px;
  }
  .periodSelect {
    width: 200px;
  }

  .label {
    color: #666;
    font-variant: small-caps;
    margin-left: 10px;

    &:first-child {
      margin-left: 0px;
    }
  }

  .permissionInvalid {
    border-color: red !important;
  }
  .permissionBox {
    display: flex;
    padding: 0px 6px 0px 6px;
    border: 1px solid #666;
    border-radius: 5px;

    & > * {
      margin-right: 10px;
    }
    & > *:last-child {
      margin-right: 0px;
    }
  }

  & > * {
    margin-right: 10px;
  }
}

.existingTitle {
  display: flex;
  align-items: center;
  color: #666;
  min-width: 80px;

  .tokIcon {
    color: #333;
    font-size: 0.9em;
    margin-right: 5px;
  }
}

.existingHeader {
  display: flex;
  align-items: flex-start;

  .editToken {
    display: flex;
    max-width: unset;
    .tokenView {
      width: 450px;
    }
    & > * {
      margin-right: 5px;
    }
  }

  .expiration {
    margin-left: 10px;
    display: flex;
    color: #888;

    .expired {
      color: red;
      text-decoration: line-through;
    }

    .label {
      color: #444;

      &::after {
        content: ":";
        margin-right: 5px;
      }
    }
  }
}

.permissions {
  display: flex;
  margin-left: 20px;
  .label {
    color: #444;

    &::after {
      content: ":";
      margin-right: 5px;
    }
  }

  .box {
    color: #666;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    display: flex;
    padding: 0px 6px 0px 6px;
    border: 1px solid #444;
    border-radius: 5px;

    & > * {
      margin-right: 10px;
    }

    & > *:last-child {
      margin-right: 0px;
    }

    .active {
      color: darkgreen;
      margin-right: 5px;
    }

    .inactive {
      color: darkred;
      margin-right: 5px;
    }
  }
}

.settingsHeader,
.eventHeader,
.schedulerHeader,
.keysHeader {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .label {
    color: #888;
    font-variant: small-caps;
    margin-right: 5px;

    &::after {
      content: ":";
    }
  }
  .input {
    width: 300px;
    margin-right: 15px;
  }
}

.editToken {
  display: flex;
  //min-width: 650px;

  & > * {
    margin-right: 5px;
  }
}

.tokenHidden {
  position: relative;
  align-self: flex-end;
}

.schedulerAdd {
  color: #666;
  font-size: 0.9em;

  &:hover {
    color: #aaa;
  }
}

.cronString {
  color: #888;
}

.retryTitle {
  color: #bbb;
}

.retryHeader {
  display: flex;

  .goBack {
    display: flex;
    margin-right: 20px;

    .label {
      font-variant: small-caps;
      color: #888;
      width: 100px;
      margin-bottom: 0px !important;

      &::after {
        content: ":";
      }
    }

    .select {
      width: 80px;
    }
  }
}
