.header {
  display: flex;
  align-items: center;

  .label {
    color: #888;
    font-variant: small-caps;
    margin-left: 10px;

    &::after {
      content: ":";
      margin-right: 5px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .scheduling {
    cursor: pointer;
    font-size: 0.9em;
  }

  .arrow {
    margin-right: 10px;
    margin-left: 10px;
    color: #888;
  }

  .numResultsBox {
    width: 100px;
  }
  .zeroResults {
    margin-left: 10px;
    width: 250px;
  }
  .splitResults{
    margin-left: 5px;
  }
  .intervalSelect {
    width: 100px;
    margin-right: 10px;
  }
}

.resultsTitleBox {
  display: flex;
  align-items: center;

  .numResultsBadge {
    font-size: 0.7em;
    font-weight: 600;
    padding: 0px 5px;
    border-radius: 5px;
    background-color: olivedrab;
    color: black;
    margin-left: 5px;
  }
}
.timeBox {
  padding: 0px 6px;

  .clockIcon {
    color: deepskyblue;
    margin-right: 5px;
  }
}

.resetIcon {
  color: #888;
  margin-left: 5px;

  &:hover {
    color: olivedrab;
  }
}

.pickDateBtn {
  height: 24px !important;
  color: #888;
}

.width {
  width: 366px !important;
}

.conversionBadge {
  padding: 1px 5px;
  border-radius: 5px;
  background-color: olivedrab;
  color: black;
  font-weight: 400;
  font-size: 0.9em;
}

.exploreButton {
  background-color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 0px 8px;

  .exploreButtonText {
    display: inline-block;
    color: #eee;
    font-size: 0.9em;
    font-weight: 600;
    letter-spacing: 0px;
  }

  &:hover {
    -webkit-box-shadow: 0px 0px 8px 2px rgba(136, 136, 136, 0.4);
    -moz-box-shadow: 0px 0px 8px 2px rgba(136, 136, 136, 0.4);
    box-shadow: 0px 0px 8px 2px rgba(136, 136, 136, 0.4);

    background: rgb(77, 25, 77);
    background: linear-gradient(
      0deg,
      rgba(77, 25, 77, 1) 0%,
      rgba(49, 34, 68, 1) 35%,
      rgba(33, 47, 69, 1) 100%
    );
    border-color: #666;
  }

  .loadingIcon {
    color: greenyellow;
    margin-right: 4px;
  }
}

.relativeRangeHeader {
  display: flex;
  align-items: center;

  .rangeModeIcon {
    margin-left: 20px;
  }
}
