@import "../../scss/noscroll";

.nameBox{
    display: flex;
    align-items: center;

    .divisorio{
        width: 10px;
    }
}

.fakeHeder{
    display: flex;
    justify-content: baseline;
}

.shower{
    margin-left: 10px;
    color: #444;
    &:hover{
        color:#888;
    }
}

.id{
    margin-right: 10px;
    font-size: 0.9em;
    color:#444;
}

.personaHeader{
    display: flex;

   
}

 .label {
     color: #666;
     margin-left: 10px;

     &:first-child {
         margin-left: 0px;
     }

     &::after {
         content: ":";
         margin-right: 5px;
     }
 }
.periodSelect {
    width: 150px !important;
}

.unitSelect{
    width: 80px !important;
}

.historyHeader{
    display: flex;
    align-items: center;
    //overflow-x: hidden;
    cursor: pointer;

    &>* {
            //width: 150px;
            margin-right: 5px;
        }
}

.persona{
    margin-bottom:5px !important;
    
}

.inputBox{
    display: flex;
    align-items: center;
    cursor: pointer;

    .free{
        color: #444;
        margin-left:10px;
        &:hover{
            color: #888;
        }
    }
    .personas{
        color: dodgerblue;
        margin-left: 10px;
        &:hover {
                color: deepskyblue;
            }
    }

    .input{
        min-width:366px;
    }
}

.addBtn{
    margin-bottom: 2px;
}

.cronString{
    color:#888;
}
.historyExtra{
    .infoBtn{
        margin-left: 5px;
        text-transform: capitalize;
        font-size: 0.8em;
    }

    .analiticaBtn{
        border-color: deeppink;
        color: deeppink;

        &:hover{
            background-color: deeppink;
            color: black;

            .analiticaIcon {
                    color: black;
                }
        }
    }

    .analiticaIcon{
        color: deeppink;
        margin-right: 5px;
    }
    .userIcon{
        margin-right: 5px;
        color:#666;
    }
}