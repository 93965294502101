.schedule-filters {
  margin-bottom: 20px;
  float: right;
}
.diagram-container {
  position: relative;
  z-index: 2;
  display: block;
  min-height: 100%;
  background-color: #000 !important;
  border: 2px solid #333;
  border-radius: 8px;
  width: 100%;
  padding: 1px;
  padding-bottom: 0px;
}

.scheduled-item {
  overflow: hidden;
  border: 1px solid #444;
  opacity: 0.9;
  border-radius: 4px;
  padding-left: 8px;
  font-weight: 500;
}

.scheduler-group-blocks,
.scheduler-group-rules {
  .title {
    overflow: hidden;
    padding-left: 6px;
    padding-right: 6px;
  }
}

@function text-color($color) {
  @if (lightness($color) > 50) {
    @return #000000; // Lighter backgorund, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}
//$colors-list: #4d194d #3e1f47 #312244 #272640 #212f45 #1b3a4b #144552 #0b525b #065a60 #006466;
$colors-list: #b7094c #a01a58 #892b64 #723c70 #5c4d7d #455e89 #2e6f95 #1780a1 #0091ad #006466;

@each $current-color in $colors-list {
  $i: index($colors-list, $current-color) - 1;
  .scheduled-#{$i} {
    background-color: $current-color !important;
    color: text-color($current-color) !important;
    border-color: darken($current-color, 20%) !important;

    &.selected {
      $selected-color: lighten($current-color, 30%);
      background-color: $selected-color !important;
      color: text-color($selected-color) !important;
      border-color: darken($selected-color, 20%) !important;
      font-weight: 600;
    }
  }
}

.scheduler-group-blocks,
.scheduler-group-rules {
  background: #111 !important;
}

.scheduled-rules.selected {
  background: rgb(136, 136, 136) !important;
}
