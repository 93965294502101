.container {
  display: inline-block;
}

.icon {
  color: #666;
  font-size: 13px;
  cursor: pointer;
}

.body {
  max-width: 800px;
  text-align: left !important;
  padding: 12px 12px 0px 12px;
  max-height: 400px;
  overflow-y: scroll;
}

.body::-webkit-scrollbar {
  display: none;
  background-color: #111;
  width: 16px;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* background of the scrollbar except button or resizer */
.body::-webkit-scrollbar-track {
  background-color: #111;
}

/* scrollbar itself */
.body::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 16px;
  border: 4px solid #111;
}

/* set button(top and bottom of the scrollbar) */
.body::-webkit-scrollbar-button {
  display: none;
}

.toolbar {
  padding: 0px 8px 0px 8px;
  text-align: right !important;
  cursor: pointer;
  color: #666;
  margin-top: 10px;

  .comments {
    .icon {
      font-size: 1em;
      margin-right: 4px;
      color: darkgoldenrod;
    }

    .icon:hover {
      color: #444;
    }

    .num {
      font-size: 0.9em;
      color: #888;
    }
    .num:hover {
      color: darkviolet;
    }
  }

  .iconEdit {
    float: left;
    margin-right: 2px;
  }

  .iconEdit:hover {
    color: #444;
  }
}

.editorContainer {
  padding: 0 !important;
  //width: 400px;
  .editorWrapper {
    //width: 400px;
  }
  .editor {
    //width: 400px;
    margin-top: -4px;
    background-color: #222;
    color: #eee;
    min-height: 250px;
    max-height: 600px;
    padding: 6px 14px 6px 14px;
  }

  .editorToolbar {
    //background-color: #aaa;
    color: #222;
  }

  .editorToolBtn {
    margin-top: 0px;
    background-color: #333;
    text-align: right !important;
    padding: 8px;
    .save {
    }
    .reset {
      margin-right: 6px;
    }
  }
}

.noteEditingBox {
  background-color: darkmagenta;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 20px;
  height: 410px;
  overflow-y: scroll;
}

.notesContainer {
  .box {
    min-width: 600px;
    max-height: 500px;
    overflow-y: scroll;
    padding: 8px;
    color: #111;

    .note {
      background-color: gold;
      border: 2px solid gold;
      border-radius: 6px;
      padding: 6px;
      margin-bottom: 15px;

      .header {
        font-variant: small-caps;
        //margin-bottom: 20px;
        height: 26px;
        border-bottom: 1px dotted #888;

        .author {
          font-weight: 600;
          display: inline-block;
          float: left;
          background-color: rgb(221, 165, 24);
          border: 1px soldi darkgoldenrod;
          border-radius: 4px;
          padding: 0px 8px 0px 8px;
          color: #222;
        }
        .date {
          float: right;
          display: inline-block;
        }
      }

      .body {
        background-color: gold;
      }

      .toolbar {
        padding-bottom: 16px;
        .iconEdit {
          float: right;
          color: #888;
        }
      }
    }
    .note:last-child {
      margin-bottom: 0px;
    }
  }

  .toolbar {
    display: block;
    padding-top: 1px;
    padding-bottom: 10px;

    .add {
      display: inline-block;
      margin-right: 6px;
      .icon {
        margin-right: 4px;
        color: green;
      }
    }
    .close {
      display: inline-block;
    }
  }
}
