.filterBox{
    display: flex;
    align-items: center;
    .search{
        width: 90%;
        display: block;
        position: static;
    }

    .icon{
        margin-left: -5px;
        margin-right: 10px;

        &:hover{
            color: darkviolet;
        }
    }
}