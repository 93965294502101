.switchTitle {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 60px;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;

  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .labelCol {
      width: 80px;
    }
    .contentCol {
    }
  }
}

.header {
  display: flex;
  align-items: center;
  .label {
    font-variant: small-caps;
    color: #888;
    margin-left: 10px;

    &::after {
      content: ":";
    }
  }
  .endpointTitle {
    font-size: 0.8em;
    text-transform: uppercase;
    margin-left: 10px;
  }
}
