.endpoints-container {
  cursor: pointer;
  padding: 16px;
  border: 1px solid #333;
  border-radius: 8px;
  background-color: #000;

  .form-group {
    margin-bottom: 4px;
  }
}

.duration-box {
  font-size: 1em;
  color: #888;
  display: flex;
  align-items: center;
  margin-left: 10px;

  .duration-item {
    display: flex;
    align-items: center;
    font-size: 1em;
    color: #eee;
    margin-left: 8px;
    margin-right: 4px;
    font-weight: 700;

    &:first-child {
      margin-left: 0px;
    }
  }

  .duration-icon {
    font-size: 1.2em;
    margin-right: 4px;
    color: #444;
  }
}

.infields-select {
  display: inline-block;
  width: 370px;
  margin-right: 6px;
}

.required-field-container {
  margin-top: 10px;
  margin-bottom: -6px;
  margin-left: -6px;

  .required-field {
    margin-left: 6px;
    margin-bottom: 6px;
  }
}

.deco-select {
  display: inline-block;
  width: 366px;
  margin-right: 10px;
}

.suggested-box {
  margin-top: 10px;
  display: flex;
  align-items: center;

  .suggested-icon {
    height: 22px;
    font-size: 1em;
    color: darkorange;
    margin-right: 8px;
    margin-left: 10px;
  }
  .suggested-title {
    font-size: 1em;
    font-variant: small-caps;
    margin-right: 6px;
  }
  .suggested-field {
    cursor: pointer;
    height: 22px;
    font-size: 0.9em;
    font-weight: 300;
    margin-right: 6px;
    background-color: #222;
    padding: 0px 6px 0px 6px;
    border: 1px solid #333;
    border-radius: 4px;
    margin-bottom: 4px;
  }
}

.settings-extra {
  display: flex;
  justify-content: space-between;

  .active-check {
    margin-left: 10px;
  }
  .sitemap-check {
    margin-left: 10px;
  }
}

.endpoint-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .item {
    display: flex;
    align-items: center;

    &::after {
      content: "|";
      margin-left: 8px;
      margin-right: 8px;
      color: #333;
    }

    &:last-child::after {
      content: "";
    }

    .key {
      font-variant: small-caps;
      color: #888;

      &::after {
        content: ":";
        margin-right: 6px;
      }
    }
  }
}

.prepend-time {
  background-color: #222 !important;
  border-color: #444 !important;
  color: #eee !important;
  font-size: 0.8em;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;
  height: 27px !important;
}

.params {
  display: flex;
  flex-direction: column;

  .params-box {
    display: flex;
    margin-top: 10px;

    &:first-child {
      margin-top: 0px;
    }

    .label {
      margin-left: 20px;
      margin-right: 10px;
      min-width: 70px;
      // font-variant: small-caps;
      // color: #888;

      &:first-child {
        margin-left: 0px;
        width: 50px;
      }
    }

    .input-cache-duration-group {
      flex: unset;
      width: unset;
      .input-cache-duration {
        flex: unset;
        width: 366px;
      }
    }
  }
}

.required-badge {
  font-size: 0.8em;
  color: #000;
  background-color: darkolivegreen;
  padding: 0px 6px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;

  span {
    color: #fff;
    margin-right: 2px;
    font-weight: 400;
  }
}

.decorators-box {
  display: flex;
  margin-bottom: -10px;

  .decorators-sub {
    width: 50%;
    margin-left: 10px;

    &:first-child {
      margin-left: 0px;
    }
  }
}

.decorators-container {
  display: flex;
  flex-direction: column;

  .decorators-select-heder {
    display: flex;
  }
}

.decorator-header {
  display: flex;

  .decorator-badge {
    margin-left: 10px;
    padding: 0px 6px;
    font-size: 0.8em;
    text-transform: uppercase;
    border-radius: 5px;
    color: #000;
    font-weight: 600;

    &:first-child {
      margin-left: 0px;
    }
  }
  .decorator-badge:first-child {
    background-color: darkslateblue;
    span {
      color: #fff;
      margin-right: 4px;
    }
  }

  .decorator-badge:last-child {
    background-color: darkmagenta;
    span {
      color: #fff;
      margin-right: 4px;
    }
  }
}

.blockRow {
  display: flex;
  align-items: center;

  .linkButton {
    margin-left: 6px;
  }
}

.accessors {
  margin-top: 10px;

  .accessor-label {
    color: #888;
    font-variant: small-caps;
  }

  .last {
    margin-top: 10px;
  }

  .accessor-header {
    display: flex;

    .accessor-header-label {
      color: #888;
      font-variant: small-caps;
      margin-right: 6px;

      &::after {
        content: ": ";
      }
    }

    .accessor-header-value {
      margin-right: 10px;
    }
  }
}

.select-row{
  width: 100%;
  display: flex;
}