.diffIcon {
  //display: flex;
  align-items: center;
  font-family: monospace;
  margin-left: 5px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }

  .diff {
    margin-left: 3px;
  }
}

.up {
  color: green;
}

.down {
  color: red;
}
