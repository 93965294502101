.header {
  display: flex;
  align-items: center;

  .tie {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .input {
    width: 60px;
    height: 22px !important;
  }

  .check {
    margin-left: 6px;
  }

  .label {
    color: #888;
    font-variant: small-caps;
    &::after {
      content: ":";
      margin-right: 6px;
    }
  }
  .value {
    font-size: 0.8em;
    font-weight: 600;
    color: #fff;
    background-color: #333;
    border-radius: 5px;
    padding: 0px 6px;
  }
}

.queryDisease {
  margin-left: 4px;
  color: #222;
}

.ruleHeader{
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  .andOr {
      margin-right: 10px;
    }
}