@import "./noscroll";
@import "./table.scss";

@mixin formElement {
  @include noScrollbar;
  background-color: #222 !important;
  color: white;
  border: 1px solid #444;

  :focus {
    background: blue;
    color: white;
  }
}

@mixin noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.custom-select-button {
  position: relative;
  background-color: #222;
  color: white;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 0.9em;
}

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  background-color: #000;
  color: whitesmoke;
  //font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-family: "Roboto", sans-serif !important;
  // Disable swipe gestures
  overscroll-behavior-x: none;
  // Disable bounce
  //height: 100%;
  overflow: auto;
}

// SCROLLABAR

body::-webkit-scrollbar {
  display: none;
  background-color: #111;
  width: 16px;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #111;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 16px;
  border: 4px solid #111;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #888;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

input[type="checkbox" i]:focus {
  outline: none !important;
  box-shadow: none !important;
}

.table-dark {
  background-color: #000;
}

.btn-outline-primary {
  border-color: rgb(10, 68, 122);
  color: rgb(10, 68, 122);

  &:hover {
    background-color: rgb(5, 35, 63);
    color: #fff;
  }
}

$danger-color: #dd3444;

.btn-outline-danger {
  border-color: darken($danger-color, 30%);
  color: darken($danger-color, 25%);

  &:hover {
    background-color: darken($danger-color, 55%);
    color: lighten($danger-color, 0%);
  }
}

.error-message {
  display: block;
  color: #b00;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;

  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.modal-dialog {
  margin-top: 15%;
}

hr {
  border-top: 1px solid #222 !important;
}

.dropdown-menu {
  background: #000;
  min-width: 100%;
  z-index: 9999;

  border: 1px solid #666;
  box-shadow: 1px 1px 4px #222;
  a {
    color: #aaa;
  }

  a:hover {
    background: black;
    color: #fff;
  }

  input {
    width: 100%;
  }
}

.dropdown-header {
  text-transform: uppercase;
  font-weight: 600;
}

.dropdown-item-list {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}



.form-control:focus {
  color: white;
}

.select-button,
.form-control {
  @include formElement;
}

.modal-content {
  background: #111;
  color: #eee;
  border-color: #888;

  .modal-body {
    background: #000;
  }
  .close {
    color: #eee;
  }
}


.filter-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  font-variant: small-caps;
  color: #666;
  &:last-child {
    margin-right: 0px;
  }
}

.filter-option:hover {
  color: #888;
}

.filter-option.active {
  color: #fff;
}

.filter-option::after {
  color: #222;
  font-family: "Courier New", Courier, monospace;
  content: "|";
  margin-left: 16px;
}

.filter-option:last-child::after {
  content: "";
}

.filter-icon {
  margin-right: 6px;
  margin-left: 6px;
}

.lds-ellipsis {
  margin-top: -24px;
  margin-left: 40px;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #e3ff00;
  opacity: 0.9;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/*********************/
/* Bootstrap Badge   */
/*********************/
.badge {
  display: unset !important;
  background-color: #444 !important;
  color: #fff;
}
// .badge:empty {
//   display: none;
// }

// .btn .badge {
//   position: relative;
//   top: -1px;
// }

/*********************/
/* Bootstrap Switch  */
/*********************/
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: rgb(95, 95, 95);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.5);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #126801;
  border-color: #3a9100;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

// CUSTOM SELECT
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.5em;
  background: #000;
  border: 1px solid #444 !important;
  box-shadow: 1px 1px 4px #222;
  border-radius: 3px;
  padding: 1em 2em 1em 1em;
  font-size: 1em;
  background: url(chevron-down-solid.svg) no-repeat;
  background-position: right 7px center;
  background-size: 14px 14px;
}

.select-container {
  position: relative;
  display: inline;
}
.select-container:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}
.select-container:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 0.3em;
  right: 0.75em;
  border-top: 8px solid black;
  opacity: 0.5;
}
select::-ms-expand {
  display: none;
}

// DISABLE MARGIN ON MOBILE
@media only screen and (max-width: 1660px) {
  .container-fluid,
  .content-pane {
    // margin: 0px !important;
    // margin-top: 200px;
    //margin-left: 2%;
    // margin-right: auto;
    max-width: 100% !important;
  }
}

.arrow::before {
  border-right-color: red;
}

.row {
  margin: 0px !important;
  margin-bottom: 10px !important;
  align-items: center;
  justify-items: center;

  &:last-child {
    margin-bottom: 0px !important;
  }
}

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1 !important;
}

button {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

input[type="text"],
input[type="number"],
.custom-select-button,
select {
  height: 27px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

input[type="text"],
input[type="number"],
textarea {
  @include formElement;
}

input[type="password"] {
  margin-bottom: 0px !important;
}

.col-sm-1 {
  align-self: flex-start;
}



// MULTI-SELE
.rmsc {
  min-width: 364px !important;
  font-size: 0.9em !important;
  --rmsc-main: #444 !important;
  --rmsc-hover: #888 !important;
  --rmsc-selected: #222 !important;
  --rmsc-border: #444 !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #222 !important;
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 4px !important; /* Radius */
  --rmsc-h: 25px !important; /* Height */
}

.container-fluid{
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.dropdown-divider {
  border-top-color: #444 !important;
}

.navbar-brand{
  margin-right: 8px !important;
  margin-left: 12px !important;
}

.recharts-tooltip-wrapper,
.recharts-default-tooltip{
  background-color: #000 !important;
  border-radius: 6px !important;
  border-color: #888 !important;
  box-shadow: 0px 10px 15px -3px rgba(94, 93, 93, 0.1) !important;

  .recharts-tooltip-label{
    font-weight: 900;
    margin-top: -5px !important;
    padding-bottom: 2px !important;
    border-bottom: 1px solid #444 !important;
  }

  .recharts-tooltip-item{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}