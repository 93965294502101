@import "../../../scss/noscroll";
@mixin toggler {
  //font-family: FontAwesome;
  font-style: normal !important;
  font-size: 1em;
  width: 26px;
}

.react-dropdown-tree-select {
  //min-height: 30px;
  position: relative;
  user-select: none;

  .dropdown {
    display: flex !important;
    align-items: center;
    color: #eee;
    display: block;

    .placeholder {
      color: #888;
      font-size: 0.9em;
    }
    a:hover {
      text-decoration: none !important;
    }

    &:active,
    &:focus {
      outline: none !important; //transparent auto 0px !important;
    }

    .dropdown-trigger {
      border: 0px !important; //1px solid #666 !important;
      border-radius: 4px;
      padding: 0px !important; //0px 2px 0px 2px !important;

      &:hover {
        border-color: darkorange !important;
      }

      &:active,
      &:focus {
        outline: none !important; //transparent auto 0px !important;
      }
    }
    .arrow.top:after {
      @include toggler;
      &::after {
        content: "/\\" !important;
      }
      &:hover {
        font-style: normal !important;
      }
    }
    .arrow.bottom:after {
      @include toggler;
      &::after {
        content: "\/" !important;
      }

      &:hover {
        font-style: normal !important;
      }
    }

    .dropdown-content {
      @include noScrollbar;
      max-height: 300px;
      top: 30px;
      //z-index: 99999999;
      background-color: #222 !important;
      border: 1px solid #444;
      min-width: 266px;

      input {
        background-color: #333 !important;
        padding: 2px 8px 2px 8px !important;
        border: 1px solid #666 !important;
      }
      .search {
        position: sticky;
        width: 100%;
        border: none;
        border-bottom: solid 1px #222;
        outline: none;
      }

      .node {
        padding-right: 20px !important;
        padding-top: 2px !important;
        padding-bottom: 0px !important;
        height: 30px;
      }

      .node::first-child {
        padding-top: 0px !important;
      }

      .toggle.collapsed:after {
        @include toggler;
        &::after {
          content: "/\\" !important;
        }
      }

      .toggle.expanded:after {
        @include toggler;
        &::after {
          content: "/\\" !important;
        }
      }

      .node.focused {
        background-color: #444 !important;
      }
    }

    .tag-list{
      
    }
    .tag {
      background-color: #444 !important;
      color: #eee !important;
      padding: 0px 2px 0px 8px !important;
      font-size: 1em;
      border-color: #666 !important;
      border-radius: 2px;

      button {
        font-size: 1.1em;
        &:hover {
          color: #f00;
        }
      }
    }
  }
}
