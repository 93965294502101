@mixin opaque {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

@mixin andOr {
  display: inline-block;
  width: 65px;
  text-align: left;
  border-radius: 4px;
  color: #222;
  font-size: 0.8em;
  font-weight: 900;
  margin-left: 6px;
  margin-right: 4px;

  &:hover {
    background-color: #fff;
    color: #111;
  }
}
.operatorsRestricted {
  border-color: darkmagenta !important;
}

.extraBox {
  //color:#1d0906;
  display: flex;
  flex-wrap: nowrap;

  .extra {
    align-self: baseline;
    margin-right: 20px;
  }

  .toolbar {
    align-self: flex-end;
    width: 50px;
    //height: 30px;
    text-align: right;

    .extraMenu {
      a {
        color: #666;

        &:hover {
          color: #aaa;
        }
      }
    }

    .copyIcon {
      margin-right: 8px;
      color: deepskyblue;
    }

    .pasteIcon {
      margin-right: 8px;
      color: green;
    }

    .resetIcon {
      margin-right: 8px;
      color: darkorchid;
    }

    .disabledMenuItem {
      color: #444 !important;

      .resetIcon,
      .pasteIcon,
      .copyIcon {
        opacity: 0.4;
      }
    }
  }
}

.body {
  flex-direction: column;
  display: flex;

  .group {
    background-color: #000;
    cursor: pointer;
    //padding: 12px;
    border: 1px solid #222;
    border-radius: 8px;
    margin-bottom: 10px;
    flex-direction: column;
    display: flex;
    height: 100%;

    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      border-color: #888;
    }

    .groupBody {
      padding: 6px 18px 6px 18px;
    }

    .header {
      height: 100%;

      border-bottom: 1px solid #222;
      //padding-bottom: 10px;
      //margin-bottom: 10px;
      font-size: 0.7em !important;

      //border: 1px solid magenta;

      .headerBox {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: baseline;
        padding: 6px 18px 0px 18px;
        width: 100%;

        .toggler {
          margin-right: 6px;
          .toggleIcon {
            font-size: 1em;
            display: flex;
            color: #aaa;
            cursor: pointer;
          }
        }
        .logic {
        }

        .verified {
          //margin-left: 12px !important;
          margin-right: 6px;
          font-size: 1.8em !important;
          align-self: flex-start !important;
        }

        .spacer {
          display: flex;
          flex-grow: 1;
          align-self: center;
          flex-shrink: 2;
          min-height: 31px;
          //border: 1px solid red;

          .basicRule {
            padding: 0px 20px 0px 20px;
            align-self: center;
          }
        }
      }

      .toolbar {
        align-self: flex-start;
        flex-basis: 116px;
        @include opaque;

        .buttonGroups {
          justify-content: flex-end;

          .addGroupIcon {
            left: 3px;
            color: #111;
            margin-right: 4px;
            margin-left: 6px;
          }
        }
      }
    }

    .condition {
      background-color: #000;
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: baseline;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }

      .disableAndOrButtons {
        border-color: #000;
        background-color: #111;
        //   border-radius: 4px;
        width: 81.55px;
        //   height: 10px;
      }

      .andOrButtons {
      }
      .logic {
        margin-left: 6px;
        margin-right: 6px;
        flex-grow: 1;

        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;

        .valueOne {
          flex-basis: 100%;
          padding: 2px;
          margin-right: 6px;
        }
        .operator {
          flex-basis: 100%;
          padding: 2px;
          margin-right: 6px;
          height: 31px;

          .operatorsLoading {
            transition-timing-function: ease;
            background-color: #222;
            width: 100%;
            height: 31px;
            border: 1px solid #444;
            border-radius: 4px;
            &::after {
              color: #fff;
              content: "  ";
            }
          }
        }
        .valueTwo {
          align-self: flex-start;
          flex-basis: 100%;
          padding: 2px;
        }
      }
      .toolbar {
        @include opaque;
      }
    }
  }
}

.button {
  color: #666;
}

.verified {
  align-self: center;
  font-size: 1.2em;

  .ok {
    color: green;
  }
  .ko {
    color: red;
  }

  .error {
    color: darkorange;
  }
}

// .rulePreview {
//   align-self: center;
//   //padding: 0px 20px 0px 20px;
//   font-family: "Menlo", "Courier New", Courier, monospace;
//   font-size: 0.7em;
//   color: #aaa;
// }

.andOrButtonGroup {
  * > button {
    //height: 24px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
