.container {
  display: flex;
  align-items: baseline;

  .from {
  }
  .to {
  }
  .separator {
    margin-left: 8px;
    margin-right: 8px;
  }
}
