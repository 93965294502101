@charset "UTF-8";
@font-face {
  font-family: Notification;
  src: url(fonts/notification.eot?s3g3t9);
  src: url(fonts/notification.eot?#iefixs3g3t9) format("embedded-opentype"),
    url(fonts/notification.woff?s3g3t9) format("woff"),
    url(fonts/notification.ttf?s3g3t9) format("truetype"),
    url(fonts/notification.svg?s3g3t9#notification) format("svg");
  font-weight: 400;
  font-style: normal;
}

.notificationContainer {
  position: "fixed";
  top: 60;
  right: 10;
  min-height: "200px";
  z-index: "99999";
}

.notification {
  cursor: pointer;
  min-width: 250px;
  min-height: 35px;
  opacity: 0.6 !important;
  border: 2px solid #444;
  border-radius: 8px;

  &:hover {
    opacity: 1 !important;
  }
}
.notification:before {
  position: absolute;
  top: 25px;
  left: 10px;
  margin-top: -14px;
  display: block;
  font-family: Notification;
  width: 28px;
  height: 28px;
  font-size: 20px;
  text-align: center;
  line-height: 24px;
}

.toast-body {
  padding-left: 40px !important;
  font-size: 1em;
  font-weight: 600;
}
.notification-info {
  background-color: #2f96b4 !important;
}
.notification-info:before {
  content: "";
}
.notification-success {
  background-color: green !important;
  color: #222;
}
.notification-success:before {
  content: "";
}
.notification-warning {
  background-color: #f89406 !important;
}
.notification-warning:before {
  content: "";
}
.notification-error {
  background-color: #bd362f !important;
}
.notification-error:before {
  content: "";
}
