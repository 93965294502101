$particleSize: 20vmin;
$animationDuration: 6s;
$amount: 20;

.animatedBackground {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #06030a;
  z-index: 0;

  .particle {
    width: $particleSize;
    height: $particleSize;
    border-radius: $particleSize;
    backface-visibility: hidden;
    position: absolute;
    animation-name: move;
    animation-duration: $animationDuration;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    //$colors: (#583c87, #e45a84, #ffacac);

    $colors: (
      #f72585,
      #b5179e,
      #7209b7,
      #560bad,
      #480ca8,
      #3a0ca3,
      #3f37c9,
      #4361ee,
      #4895ef,
      #4cc9f0
    );

    @for $i from 1 through $amount {
      &:nth-child(#{$i}) {
        color: nth($colors, random(length($colors)));
        top: random(100) * 1%;
        left: random(100) * 1%;
        animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
        animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
        transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
        $blurRadius: (random() + 0.5) * $particleSize * 0.5;
        $x: if(random() > 0.5, -1, 1);
        box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
      }
    }
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css?family=Fira+Mono:400");

.loginBox {
  position: relative;
  //background-color: rgba(0, 0, 0, 0.425) !important;
  max-width: 500px;
  margin-top: 15%;
  margin-right: auto;
  margin-left: auto;
  z-index: 1000;

  box-shadow: 0px 0px 8px #212121 !important;

  &:hover {
    box-shadow: 0px 0px 10px #313131 !important;
  }
}

.titleBox {
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    color: greenyellow;
    margin-right: 12px;
    -webkit-animation: flash linear 1s infinite;
    animation: flash linear 1s infinite;
  }

  .title {
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Courier, monospace !important;
    font-weight: 500;
    color: #aaa;
    letter-spacing: -1px;

    span {
      margin-right: 6px;
    }

    span:nth-child(2) {
      color: #444;
    }
    span:nth-child(3) {
      color: #666;
    }
  }
}

.loginInput {
  border: 3px solid #001b24 !important;
}

.loginSelect {
  border: 2px solid #001b24 !important;
}

.loginLabel {
  margin-top: 10px;
  margin-bottom: 5px !important;
  color: #eee;
  font-size: 1.1em;
  font-variant: small-caps;
  &:first-child {
    margin-top: 0px;
  }
}

.version {
  font-size: 0.8em;
  color: #888;

  &::before {
    content: "";
    margin-right: 1px;
  }
}

$scrollDuration: 15s;
$scrollInitial: 90%;
.errorContainer {
  overflow: hidden;
  position: relative;
  // border: 2px solid #111;
  // margin-bottom: 10px;

  .loginError {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: darkmagenta;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 0.8em;
    padding: 4px;
    padding-top: 0px;
    font-family: "Menlo", "Courier New", Courier, monospace;

    /* Starting position */
    -moz-transform: translateX($scrollInitial);
    -webkit-transform: translateX($scrollInitial);
    transform: translateX($scrollInitial);
    /* Apply animation to this element */
    -moz-animation: example1 $scrollDuration linear infinite;
    -webkit-animation: example1 $scrollDuration linear infinite;
    animation: example1 $scrollDuration linear infinite;
  }
}

// @-moz-keyframes example1 {
//   0% {
//     -moz-transform: translateX(100%);
//   }
//   100% {
//     -moz-transform: translateX(-100%);
//   }
// }
// @-webkit-keyframes example1 {
//   0% {
//     -webkit-transform: translateX(100%);
//   }
//   100% {
//     -webkit-transform: translateX(-100%);
//   }
// }
@keyframes example1 {
  0% {
    -moz-transform: translateX($scrollInitial); /* Firefox bug fix */
    -webkit-transform: translateX($scrollInitial); /* Firefox bug fix */
    transform: translateX($scrollInitial);
  }
  100% {
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%);
  }
}
