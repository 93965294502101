.modale {
  max-width: 80vw !important;

  .header {
    padding: 2px 8px !important;

    button {
      margin: unset !important;
      padding: unset !important;
    }
    .title {
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 500;
      font-family: Menlo, "Courier New", Courier, monospace;

      .icon {
        margin-right: 6px;

        color: goldenrod;
      }
    }
  }

  .body {
    background-color: #f5f5f5 !important;
    padding: 0px !important;
  }
}

.modaleContent {
  background-color: #444 !important;
}

.submitNewBug {
  width: 500px !important;
  height: 100px !important;
}
