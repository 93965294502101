.greaterBox,
.lessBox,
.rangeBox,
.sortBox,
.listIdBox,
.phraseBox,
.splitterAndPercBox,
.searchBox,
.randomBox,
.mltBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 10px;

  & > * {
    margin-right: 10px;
  }
}

.randomBox,
.greaterBox,
.lessBox,
.rangeBox,
.sortBox,
.splitterBox,
.phraseBox,
.splitterAndPercBox,
.searchBox {
  .select {
    display: flex;
    flex-direction: column;
    .label {
      color: #888;
      font-variant: small-caps;
    }

    .content {
      width: 366px;
    }
  }
}

.dateElements {
  margin-left: 10px;
  padding: 6px;
  border: 1px solid #111;
  border-radius: 5px;
}

.seed {
  margin-left: 10px;
  width: 310px !important;
}
