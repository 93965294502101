// .header {
//   cursor: pointer;
//   display: flex;
//   align-items: baseline;
//   border-bottom: 1px solid #333;
//   padding-bottom: 10px;
//   margin-bottom: 10px;

//   .toggler {
//     color: #888;
//     margin-right: 8px;
//     font-size: 1em;
//   }
//   .title {
//     font-size: 1.3em;
//     color: #aaa;
//   }

//   .spacer {
//     flex-grow: 4;
//   }

//   .reload {
//     margin-right: 10px;
//     color: #888;
//   }
// }

.cache {
  margin-left: 20px;
  display: flex;

  .key {
    font-size: 0.9em;
    font-weight: 300;
    margin-right: 6px;
    background-color: #111;
    padding: 0px 6px 0px 6px;
    border: 1px solid #222;
    border-radius: 4px;
  }

  .hitsPerPage,
  .page {
    background-color: #1d3557;
  }
  .contentId {
    background-color: #e63946;
    color: #000;
  }
  .userId {
    background-color: #f1faee;
    color: #000;
  }
  .clientIP {
    background-color: #a8dadc;
    color: #000;
  }
  .extraId {
    background-color: #b3ff00;
    color: #000;
  }
  .query {
    background-color: #457b9d;
    color: #fff;
  }
  .context {
    background-color: #1d3557;
    color: #fff;
  }
  .params {
    background-color: #422081;
    color: #fff;
  }
  .tenant {
    background-color: #ffbe0b;
    color: #000;
  }
  .property {
    background-color: #fb5607;
    color: #000;
  }
  .uxReference {
    background-color: #ff006e;
    color: #fff;
  }
}

.body {
  //margin-top: 10px;
  padding: 12px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;

  .block {
    width: 100%;
    display: flex;

    .label {
      font-size: 1em;
      color: #888;
      margin-right: 10px;
      width: 100px;
      text-align: right;
    }

    .elements {
      display: flex;
      flex-wrap: wrap;
      width: 98%;

      .element {
        cursor: pointer;
        font-size: 0.9em;
        font-weight: 300;
        margin-right: 6px;
        background-color: #111;
        padding: 0px 6px 0px 6px;
        border: 1px solid #222;
        border-radius: 4px;
        margin-bottom: 4px;
      }
    }
  }

  .conditions {
    .label {
      display: flex;
      font-size: 1em;
      color: #888;
      margin-right: 10px;
      width: 100px;
      border-bottom: 1px solid #333;
      width: 100%;
      margin-bottom: 5px;
      padding-bottom: 5px;
      margin-top: 10px;
    }

    .box {
      display: flex;
      flex-direction: column;
      padding-left: 4px;

      .element {
        width: 100%;
        font-family: "Menlo", "Courier New", Courier, monospace;
        font-size: 0.8em;
        &::before {
          content: "> ";
        }
      }
    }
  }
}

.reloadIcon {
  color: #888;
}

.idFieldsBox {
  .idFieldsSelectBox {
    display: flex;
    .idFieldsSelect {
      width: 366px;
    }
    .addBtn {
      margin-left: 10px;
      .addIcon {
        margin-right: 5px;
      }
    }
  }

  .idFieldsListBox {
    display: flex;
    margin-top: 10px;
    color: #888;

    .field {
      font-size: 0.9em;
      padding: 0px 6px;
      border: 1px solid #888;
      border-radius: 4px;
      margin-right: 5px;

      &:hover {
        background-color: #888;
        color: #fff;
      }

      .delete {
        cursor: pointer;
        font-size: 0.6em;

        &:hover {
          color: red;
        }
      }
    }
  }
}
