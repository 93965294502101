@import "../../scss/noscroll";

.container {
  position: relative;
  z-index: 999999;
  background-color: #111 !important;
  min-height: 200px;
  min-width: 750px;
  //margin-top: 6px;
  //border: 2px solid greenyellow;
  border-radius: 8px;

  .header {
    //padding-bottom: 5px;
    border-bottom: 1px solid greenyellow;
    margin-bottom: 10px;
    .box {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 2px 10px;

      .title {
        font-size: 1.2em;
        color: greenyellow;
        font-weight: 500;

        .icon {
          font-size: 0.9em;
          margin-right: 4px;
          color: darken(#adff2f, 10%);
        }
      }
      .switch {
      }
    }
  }

  .updateBox {
    padding: 5px;
  }

  .body {
    .row {
      padding-bottom: 10px;
      border-bottom: 1px dotted #222;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
        padding-bottom: 0px;
        border: 0px;
      }

      .dataTitle {
        font-variant: small-caps;
        color: #888;
      }
      .lastUpdate {
      }
      .extra {
      }
      .action {
        display: flex;
        flex-direction: column;
        text-align: right;

        button {
          margin-top: 6px;

          &:first-child {
            margin-top: 0px;
          }

          .icon {
            margin-right: 3px;
          }
        }
      }

      .realms {
        max-width: 400px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -5px;

        .realm {
          cursor: pointer;
          margin-left: 4px;
          background-color: #666;
          color: #000;
          margin-bottom: 5px;

          &:hover {
            background-color: yellowgreen;
          }
        }
      }
    }
  }
}

.metadataButtons {
  display: flex;
  flex-direction: column;
}

.pageTab {
  .icon {
    margin-right: 5px;
    color: greenyellow;
  }
}

.exportBox {
  display: flex;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .downIcon {
    margin-right: 5px;
    color: #fff;
  }

  .dbBtn {
    color: darkmagenta;
    margin-bottom: 10px;
    width: 150px;
  }
}

.warningBox {
  @include noScrollbar;
  max-height: 70vh;
  overflow-y: scroll;
  padding: 5px;
}

.waringCounter {
  font-size: 0.8em;
  margin-left: 5px;
  padding: 0px 6px 0px 6px;
  border: 1px solid yellowgreen;
  border-radius: 5px;
}

.pills {
  margin-top: -15px !important;
}

.analiticaContainer {
  margin: 0px 10px 10px 10px;
  .analiticaTitle {
    display: flex;
    align-items: center;

    .length,
    .percentage {
      display: flex;
      align-items: center;

      margin-left: 10px;
      margin-right: -5px;
      font-size: 0.7em;
      color: #fff;
      border: 1px solid #aaa;
      border-radius: 5px;
      padding: 0px 6px;
      height: 22px;
    }

    .percentage {
      border-color: deepskyblue;
    }
  }

  .analiticaBox {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    @include noScrollbar;
    max-height: 150px;
    overflow-y: scroll;
    padding: 5px;

    .endpoint {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .property {
        color: #888;
      }

      .separator {
        &::after {
          content: ">";
          font-size: 0.8em;
          font-weight: 900;
          margin-left: 3px;
          margin-right: 3px;
        }
      }

      .title {
        color: #aaa;
      }

      .divider {
        flex-grow: 2;
      }
    }
  }
}
