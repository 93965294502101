.bs-tooltip-right .arrow::before {
  border-right-color: #444;
}

.bs-tooltip-left .arrow::before {
  border-right-color: #333;
}

.bs-tooltip-top .arrow::before {
  border-right-color: #333;
}

.bs-tooltip-bottom .arrow::before {
  border-right-color: #333;
}

.tooltip-inner {
  max-width: 800px !important;
  padding: 0 !important;
  border: 2px solid #444;
  border-radius: 8px;
  background-color: #222 !important;
  color: #eee !important;
}
