.clause {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 0px 5px 1px 5px;
  border: 1px solid #666;
  border-radius: 6px;
  font-size: 0.8em;
  font-variant: small-caps;
  margin-left: 4px;
  color: #eee;
  //min-height: 24px !important;
  cursor: pointer;
  text-indent: 0px !important;

  &:first-child {
    margin-left: 0px;
  }
}

// .clause:hover {
//   border: 1.5px solid;
// }

.clause-filter {
  border-color: forestgreen;
  color: rgb(88, 223, 88);
}

.clause-exclude {
  border-color: #e63228;
  color: red;
}

.clause-boost {
  border-color: darkviolet;
  color: rgb(255, 154, 255);
}

.clause-include {
  border-color: darkturquoise;
  color: turquoise;
}

.clause-order {
  border-color: #1450aa;
  color: #519aff;
}
