
@-webkit-keyframes flash {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

@keyframes flash {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 100;
    -webkit-animation: flash linear .6s infinite;
    animation: flash linear .6s infinite;

    .counter{
        color: #fff;
        font-size: 2em;
        position: absolute;
        left: 50%;
        transform: translate(-17%, 0);
    }
    .icon{
        // margin: auto;
        position: absolute;
        top: 200px;
        font-size: 12em;
        color: greenyellow;
       
    }
    .text{
        position: absolute;
        top: 400px;
        font-family: "Montserrat", sans-serif;
        font-weight: 900;
        font-size: 2em;
    }
}