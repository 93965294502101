@mixin label {
  // font-size: 1.1em;
  // font-variant: small-caps;
  // color: #888;
  // font-weight: 600;
}

.ext-box {
  .row {
    align-items: flex-start;
    vertical-align: top;

    .label {
      @include label;
      display: flex;
    }

    .knob-gorup {
      display: inline-block;
      margin-left: 40px;
      margin-top: 0px;
      vertical-align: top;
    }
  }
}

.params-fields {
  .input {
    width: 150px;
    display: inline-block;
  }
  .equals {
    color: #666;
  }
  .btnAdd {
    margin-left: 6px;
  }
}

.params-list {
  margin-top: 10px;

  .equals {
    color: #666;
  }

  .ampersend::after {
    content: "&";
    color: #888;
    margin-left: 10px;
    margin-right: 10px;
  }

  .ampersend:last-child::after {
    content: "";
  }
}

.required-field-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.required-field {
  margin-right: 6px;
}

.deco-select {
  display: inline-block;
  width: 320px;
}

#root
  > div
  > span
  > div
  > div.content-pane
  > div
  > div.card.shadow-sm.item-container
  > div
  > form
  > div:nth-child(4)
  > div
  > div
  > div:nth-child(7)
  > div
  > div
  > div.col-sm-2 {
  @include label;
}

.header {
  display: flex;

  .header-label {
    font-variant: small-caps;
    color: #888;
    margin-right: 6px;
    margin-left: 10px;
  }
}
