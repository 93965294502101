// .login-box {
//   cursor: pointer;
//   //margin: auto;
//   width: 30rem;
//   background-color: black !important;
//   border: 2px solid #888 !important;
//   border-radius: 12px !important;
//   box-shadow: 0px 0px 31px #111 !important;
//   overflow: hidden;
//   max-height: 400px;
//   // position: absolute;
//   // top: 10px;
//   // left: 0%;
//   transform: translate(120%, -80vh);

//   &:hover {
//     box-shadow: 0px 0px 30px #888 !important;
//   }
// }

// .login-logo {
//   color: deeppink;
//   margin-right: 10px;

//   -webkit-animation: flash linear 1s infinite;
//   animation: flash linear 1s infinite;
// }

.login-quote-author {
  position: absolute;
  right: 42px;
  padding-top: 8px;
  color: #adff2f;
}

.login-quote-text {
  width: 90%;
  color: #888;
}

.login-error {
  margin-bottom: 20px;
  font-size: 1.3em;
  font-weight: 600;
  font-family: Menlo, "Courier New", Courier, monospace;
  text-align: center;
}

.login-welcome {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 600;
}

.login-username {
  color: goldenrod;
}

.logging {
  margin-left: 30%;
  color: gray;

  .logging-label {
    margin-left: 30px;
    font-variant: small-caps;
  }
}
.rootAnimation {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 60px;
  padding-bottom: 20px;
}

// .form-container {
//   display: block;
//   margin-top: 30px;
//   padding-top: 20px;
//   border-top: 1px dotted #444;
// }

.user-no-tenant {
  margin-bottom: 20px;
  color: rgb(7, 182, 182);
  font-weight: 900;
}

.simple-trans-main > div {
  cursor: pointer;
  position: absolute;
  width: 100%;

  display: block;
  will-change: transform, opacity;
}

.login-title {
  font-family: Courier, monospace !important;
  color: deeppink;
  font-size: 1.2em;
}

.login-form-button {
  margin-top: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #111 inset !important;
  -webkit-box-shadow: 0 0 0 30px #111 inset !important;
}

input:-webkit-autofill {
  font-size: 1em;
  -webkit-text-fill-color: #888 !important;
}

input[type="password"] {
  margin-bottom: 10px;
}

.logOutBtn {
  margin-right: 6px;
}
