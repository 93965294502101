.powerOffIcon {
  color: #888;
}

.metadataSelectBox {
  display: flex;

  .metaTitle {
    font-size: 1em;

    &::after {
      content: ":";
      margin-right: 10px;
    }
  }

  .metaSelectIcon {
    margin-right: 4px;
  }
}

.metadataChooser {
  display: flex;
  .metaBox {
    width: 50%;
  }
  .packBox {
    width: 50%;
  }
}
.metadataBox {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: -10px !important;

  .deleteRuleIcon {
    font-size: 0.8em;
    margin-left: 0px;
    color: #444;

    &:hover {
      color: red;
    }
  }
}

.valueBox {
  display: flex;
  align-items: center;

  .singleValue {
    display: flex;
    align-items: center;

    cursor: pointer;
    font-size: 0.9em;
    border: 1px solid #444;
    border-radius: 5px;
    padding: 0px 6px;

    &:hover {
      border-color: #aaa;
      color: deepskyblue;
    }

    .closeX {
      margin-left: 5px;
      &:hover {
        color: red;
      }
    }
  }
}

.valueSelectBox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.operator {
  color: #666;
  font-size: 0.8em;
  margin-left: 5px;
  margin-right: 5px;
}

.operatorRule {
  color: #666;
  font-size: 0.8em;
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
  margin-bottom: 10px;
  margin-right: 10px;
}

.valuesSelect,
.metaSelect {
  width: 366px;
  margin-right: 10px;
}

.metaSelectBtn {
  margin-right: 10px;
}

.titleEquals {
  color: #888;
  margin-left: 5px;
}

.valueError {
  font-size: 0.9em;
  color: black;
  border-radius: 5px;
  padding: 0px 6px;
  background-color: red;
  opacity: 0.7;
}

.groupBySelectBox {
  display: flex;
  align-items: flex-start;
  .selectBox {
    width: 50%;
  }
  .rotary {
    margin-top: -5px;
    margin-bottom: 5px;
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}

.groupByBox {
  margin-top:10px;
  margin-bottom: -10px;

  .deleteRuleIcon {
    font-size: 0.8em;
    margin-left: 0px;
    color: #444;
    margin-left: 10px;

    &:hover {
      color: red;
    }
  }
}

.rangeHeader {
  display: flex;
  .period {
    width: 70px;
    margin-right: 10px;
  }
  .unit {
    width: 200px;
  }
}

.start {
  margin-left: 10px;
  display: flex;

  .resetStartDate {
    color: #888;
    margin-left: 5px;

    &:hover {
      color: #eee;
    }
  }

  .startDate {
    font-variant: small-caps;
    color: #eee;
  }
}

.label {
  font-variant: small-caps;
  color: #888;
  margin-left: 10px;

  &:first-child {
    margin-left: 0px;
  }

  &::after {
    content: ":";
    margin-right: 5px;
  }
}

.conversionHeader {
  display: flex;

  .conversionSelect {
    width: 200px;
    margin-right: 180px;
  }

  .minOccurrences {
    display: flex;
    .minOccurrencesInput {
      width: 80px;
    }
  }
}

.settingsHeader {
  display: flex;
  .numElements {
    width: 70px;
  }

  .paramsId,
  .output {
    width: 200px;
  }

  .ContentTypeBox {
    display: flex;
  }
}

.previewReload {
  color: #888;
}

.previewLoading {
  color: greenyellow;
}

.extraSettings {
  display: flex;

  .cacheCheckbox {
    margin-left: 10px;
  }
}

.previewHeader {
  display: flex;
  height: 30px !important;

  .previewUserId {
    width: 366px;
    margin-left: 10px;
  }
}

.packBox {
  display: flex;
  align-items: baseline;

  .packSelect {
    width: 366px;
    margin-left: 10px;
  }
  .packBtn {
    max-height: 27px;
    margin-left: 10px;
  }
}

.packTitle {
  font-size: 0.9em;
  margin-right: 10px;

  .packIcon {
    margin-right: 5px;
  }
}

.useCasesExtra {
  margin-right: -10px;
}

.useCasesBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -10px;

  .operator {
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 0px;
  }
  .caseTitle {
    font-size: 1em;
    margin-right: -15px;
  }

  .subExtra {
    font-size: 0.8em;
    color: #444;

    &:hover {
      color: red;
    }
  }

  .subCase {
    max-width: 50%;
    margin-right: 10px;
    align-self: start;
  }
}

.numericField{
  width: 100px !important;
}