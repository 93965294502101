body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: 1570px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button:focus {
  outline: 0;
}

*:focus {
  outline: 0;
}

input[type="text"]:focus{
  caret-color: greenyellow !important;
  color: white;
  /* border-color: greenyellow !important; */
}
@keyframes fadeInAnimation {
  0% { opacity: 0;}
  25% { opacity: 0;}
  50% { opacity: 0;}
  75% { opacity: 0;}
  100% { opacity: 1;}
}

.santa{
    width: 36px;
      z-index: 9999;
      position: absolute;
      left: -12px;
      top: -7px;
      animation: fadeInAnimation ease 60s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
}