.popOver {
  display: flex;
  flex-direction: column;
  background-color: #000;
  width: 522px;
  max-height: 500px;
  padding: 12px;
  padding-top: 0px;
  padding-left: 14px;
  //border: 1px solid #888;
   border-radius: 8px;
  // margin-top: 10px;
  position: relative;
 // z-index: 999999;


  .row {
    //max-width: 200px !important;
    flex-wrap: nowrap;
    width: 100%;

    div:nth-child(2) {
      max-width: 400px !important; //unset !important;
    }

    .label {
      flex: none;
      padding: 0 !important;
      width: 115px !important;
      max-width: 120px !important;
      user-select: none;
    }
  }
}

.icon {
  margin-left: 10px;
  font-size: 1em;
  color: #444;

  &:hover {
    color: #888;
  }
}

.iconInvalid {
  margin-left: 10px;
  color: red;
}

$arrowSize: 8px;

.arrow {
  top: -8px;
  left: 4px !important;
  color: #888;

  width: 0;
  height: 0;
  border-left: $arrowSize solid transparent;
  border-right: $arrowSize solid transparent;

  border-bottom: $arrowSize solid #888;
}

.radioContainer{
  display: flex;

  .tab{
    margin-right: 15px;
  }
}

.userFingerprintBox{
  min-width: 500px;
}

.metaPackBox{
  display: flex;
  
  & > * {
    margin-right: 10px;
  }
}